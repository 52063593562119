<template>
  <NuxtLayout>
    <section class="Grid-layout Error-page">
      <div class="Grid-twelve Grid-twelve--subgrid Error-page__container">
        <div>
          <ux-atoms-typo :text="$t('pages.error.title')" as="h1" class="Error-page__title" color="dark" variant="expressive-heading-02" />
          <ux-atoms-typo :text="$t('pages.error.description')" as="p" class="Error-page__description" color="dark" variant="expressive-heading-05" />
          <ux-atoms-link
            :classic="false"
            :href="localePath('/')"
            :text="$t('pages.error.cta')"
            class="Button Button--m Button--outlined Error-page__cta"
          />
        </div>
      </div>
    </section>
  </NuxtLayout>
</template>

<script lang="ts" setup>
const localePath = useLocalePath();
const { t } = useI18n();

useHead(() => ({
  title: t('pages.others.title')
}));
</script>

<style lang="scss" scoped>
@use '@styles/config/mq.scss';

.Error-page {
  padding: 0rem;
  margin-top: 6.4rem;
  margin-bottom: 6.4rem;

  @include mq.media('>=large') {
    margin-bottom: 12.8rem;
  }
}

.Error-page__container {
  display: grid;
  grid-gap: 0rem;

  @include mq.media('>=medium') {
    > * {
      grid-column: 2/-2;
    }
  }

  @include mq.media('>=large') {
    > * {
      grid-column: 3/-3;
    }
  }
}

.Error-page__title {
  @include mq.media('<medium') {
    font-size: 3rem;
  }
}

.Error-page__description {
  margin-top: 12px;

  @include mq.media('<medium') {
    font-size: 1.8rem;
  }

  @include mq.media('>=large') {
    margin-top: 16px;
  }
}

.Error-page__cta {
  margin-top: 3.2rem;
}
</style>
