<template>
  <component
    :aria-label="ariaLabelComputed"
    :class="[`Typo`, `${variant}`, color ? `Typo--color-${color}` : '', textAlign ? `Typo--align-${textAlign}` : '']"
    :is="as"
    ref="typo"
  >
    <slot>{{ text }}</slot>
  </component>
</template>
<script setup lang="ts">
import { type VNode } from 'vue';

import { type TypoColor, type TypoVariant } from './types';

export interface TypoProps {
  ariaLabel?: string;
  as: keyof HTMLElementTagNameMap;
  color?: TypoColor;
  text?: null | string;
  textAlign?: 'center' | 'end' | 'start';
  variant?: TypoVariant;
}

const typo = ref<VNode>();

const props = withDefaults(defineProps<TypoProps>(), {
  ariaLabel: undefined,
  color: undefined,
  text: undefined,
  textAlign: undefined,
  variant: 'inherit'
});

// Simple props isn't enough to update it inside the aria-label and it returns the translation keys instead
const ariaLabelComputed = ref(props.ariaLabel);

defineExpose({ typo });
</script>
<style lang="scss" scoped>
@use 'Typo.scss';
</style>
