import { OLD_ARTICLE_TO_REDIRECT_TO_HP, OLD_CITY_TO_REMOVE } from '~/core/constants';
import { DEFAULT_COUNTRY } from '~/helpers/constants';
import { getRedirectUrl } from '~/helpers/url';

export default defineNuxtRouteMiddleware((to) => {
  if (to.name?.toString().includes('city')) {
    const cityRedirectUrl = getRedirectUrl(to.path, 'city');

    if (cityRedirectUrl && to.path !== cityRedirectUrl) {
      return navigateTo({ path: cityRedirectUrl }, { redirectCode: 301 });
    }

    if (OLD_CITY_TO_REMOVE.includes(to.path)) {
      throw createError({ fatal: true, statusCode: 410 });
    }
  }

  if (to.name?.toString().includes('article')) {
    const redirectUrl = getRedirectUrl(to.path, 'article');

    if (redirectUrl && to.path !== redirectUrl && !OLD_ARTICLE_TO_REDIRECT_TO_HP.includes(to.path)) {
      return navigateTo({ path: redirectUrl }, { external: true, redirectCode: 301 });
    } else if (!redirectUrl && OLD_ARTICLE_TO_REDIRECT_TO_HP.includes(to.path)) {
      const localePath = useLocalePath();
      return navigateTo({ hash: '#info-modal', path: localePath(`/${DEFAULT_COUNTRY}`) }, { redirectCode: 301 });
    }
  }
});
