import { format } from 'date-fns';
import enGB from 'date-fns/locale/en-US/index.js';
import fr from 'date-fns/locale/fr/index.js';
import { utcToZonedTime } from 'date-fns-tz';

const locales = { en: enGB, fr };

export function useDateFormat(date: Date, formatStr = 'PP', timezone?: string) {
  const zonedDate = timezone ? utcToZonedTime(date, timezone) : date;

  return format(zonedDate, formatStr, {
    // @ts-ignore
    locale: locales[useNuxtApp().$i18n.locale.value as string] as Locale
  });
}
