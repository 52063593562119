<template>
  <component
    :class="[
      'Link',
      {
        'Link--classic': classic,
        'Link--sitemap': sitemap,
        'text-underline': underline,
        'Link--disabled': disabledLink
      }
    ]"
    :is="resolvedComponent"
    :locale="locale"
    :target="target"
    :to="sanitizedHref"
    v-if="!skip"
  >
    <slot>{{ text }}</slot>
  </component>
  <a :href="sanitizedHref" class="Link Link--skip" v-else>
    <slot>{{ text }}</slot>
  </a>
</template>
<script setup lang="ts">
export interface LinkProps {
  classic?: boolean;
  component?: 'nuxt-link' | 'switch-locale-path-link';
  disabledLink?: boolean;
  href?: string;
  locale?: string;
  sitemap?: boolean;
  skip?: boolean;
  target?: '_blank' | '_parent' | '_self' | '_top';
  text?: null | string;
  underline?: boolean;
}

const props = withDefaults(defineProps<LinkProps>(), {
  classic: true,
  component: 'nuxt-link',
  disabledLink: false,
  href: undefined,
  locale: undefined,
  sitemap: false,
  skip: false,
  target: '_self',
  text: undefined,
  underline: false
});

const resolvedComponent = computed(() => {
  if (props.component === 'switch-locale-path-link') {
    return resolveComponent('SwitchLocalePathLink');
  }
  return resolveComponent('NuxtLink');
});
const sanitizedHref = computed(() => (props.href?.includes('http') ? props.href : props?.href?.replace('//', '/')));
</script>
<style lang="scss" scoped>
@use 'Link.scss';
</style>
