import { defineStore } from 'pinia';

export const useGeolocationStore = defineStore('geolocation', {
  actions: {
    getLocalizationStringify() {
      if (!this.lat || !this.lon) {
        return undefined;
      }
      return `${this.lat},${this.lon}`;
    },
    setCountryName(name: string) {
      this.userCountryName = name?.toLowerCase();
    },
    setGeolocation(lat: number, lon: number) {
      this.lat = lat;
      this.lon = lon;
    }
  },
  state: () => ({
    lat: null as null | number,
    lon: null as null | number,
    userCountryName: null as null | string
  })
});
