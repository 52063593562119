import { OLD_ARTICLES_TO_REDIRECT, OLD_CITY_TO_REDIRECT } from '~/core/constants';

/**
 * Check if it's an external url
 *
 * @param context
 * @param url
 * @returns boolean
 *
 */
export function isExternalURL(context: Record<string, unknown> | undefined, url: string): boolean {
  return isUrl(url) ? new URL(url).origin !== context?.req : false;
}

/**
 *
 * @param string
 */
function isUrl(url: string) {
  try {
    return Boolean(new URL(url));
  } catch (e) {
    return false;
  }
}

function hasNoLetters(url: string) {
  return /^[^a-zA-Z]*$/.test(url);
}

/**
 * Get the type of url in Poi Page
 * is it lat/long or POI slug
 * @param context
 * @returns boolean
 * true if it's a lat/long
 * false if it's a POI slug
 *
 */

export function isPositionSlug(slug: string): boolean {
  return hasNoLetters(slug);
}

export function cleanBaseUrl(url: string, baseUrl: string): string {
  if (url.startsWith(baseUrl)) {
    return url.replace(baseUrl, '');
  }

  return url;
}

export function getQueryParamsFromUrl(url: string): Record<string, any> {
  const queryParams = url.split('?')[1];
  const urlParams = new URLSearchParams(queryParams);

  return Object.fromEntries(urlParams.entries());
}

export function getRedirectUrl(key: string, type: string): string | undefined {
  if (type === 'article') {
    return OLD_ARTICLES_TO_REDIRECT[key];
  }

  if (type === 'city') {
    return OLD_CITY_TO_REDIRECT[key];
  }
}
