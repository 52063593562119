import { newUTCToday } from './date';

export function urlQueryParams(data: any) {
  return data
    ? Object.keys(data)
        .map((key) => {
          if (Array.isArray(data[key])) {
            return data[key].length ? data[key].map((value: any) => `${key}=${value}`).join('&') : '';
          }
          return `${key}=${data[key]}`;
        })
        .filter((value) => Boolean(value))
        .join('&')
    : null;
}

export function parseQueryParams(query: Record<string, any>): Record<string, any> {
  const parsedQuery: Record<string, any> = {};

  Object.keys(query).forEach((key) => {
    if (typeof query[key] === 'string' && ['false', 'true'].includes(query[key])) {
      parsedQuery[key] = query[key] === 'true';
    } else if (typeof query[key] === 'string' && !isNaN(Number(query[key]))) {
      parsedQuery[key] = Number(query[key]);
    } else if (parsedQuery[key]) {
      const newValue = Array.isArray(parsedQuery[key]) ? [...parsedQuery[key], query[key]] : [parsedQuery[key], query[key]];
      parsedQuery[key] = newValue;
    } else if (typeof query[key] === 'string' && !parsedQuery[key]) {
      parsedQuery[key] = query[key].split(',');
    } else {
      parsedQuery[key] = query[key];
    }
  });

  return parsedQuery;
}

export function cleanUnusedQueryParams(query: Record<string, any>, keysArray?: string[], exclude?: boolean): Record<string, any> {
  const parsedQuery: Record<string, any> = { ...query };

  Object.keys(parsedQuery).forEach((key) => {
    if (
      (Array.isArray(parsedQuery[key]) && !parsedQuery[key].length) ||
      (Array.isArray(keysArray) && !exclude && parsedQuery[key] && !keysArray?.includes(key)) ||
      (Array.isArray(keysArray) && exclude && parsedQuery[key] && keysArray?.includes(key))
    ) {
      // skip empty arrays or keys not included in includeKeys
      delete parsedQuery[key];
    }
  });

  return parsedQuery;
}

export function defaultDateQueryParams(date: string) {
  return date === newUTCToday() ? undefined : date;
}

export function defaultGuestQueryParams(guests: number) {
  return guests === 2 ? undefined : guests;
}
