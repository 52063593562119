import { type NormalizedCacheObject } from '@apollo/client/cache';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import { ApolloClients, provideApolloClients } from '@vue/apollo-composable';
import { sha256 } from 'crypto-hash';

import { Environment } from '~/core';
import { getRawCookie, parseRawCookie } from '~/helpers/cookie';

type ApolloPluginClients = Record<string, ApolloClient<unknown>>;

interface ApolloPlugin extends Record<string, unknown> {
  apollo: {
    clients: ApolloPluginClients;
    defaultClient: ApolloClient<unknown>;
  };
}

enum GqlErrorCode {
  NOT_FOUND = 'NOT_FOUND'
}

enum GqlPathError {
  EDITORIAL_CONTENT = 'editorialContent',
  INSPIRATION = 'inspiration'
}

// TODO: decide if we and to use APQ or PQ
const apolloPersistedLink = createPersistedQueryLink({
  sha256,
  useGETForHashedQueries: true
});

/**
 * @param nuxt
 * @returns
 */
function useApolloHeaders(): Readonly<Record<string, string>> {
  const nuxt = useNuxtApp();
  const config = useRuntimeConfig();
  const requestEvent = useRequestEvent();
  const requestHeaders = useRequestHeaders();
  const route = useRoute();

  const locale = nuxt.vueApp._context.config.globalProperties.$i18n.locale;

  const headers: Record<string, string> = {
    'accept-language': locale ?? requestHeaders['accept-language'],
    'cloudfront-viewer-city': requestHeaders['cloudfront-viewer-city'] || '',
    'cloudfront-viewer-country-name': requestHeaders['cloudfront-viewer-country-name'] || ''
  };

  if (config.public.appEnv !== Environment.Pro && route.query.cache === '0') {
    headers['x-cache'] = '0';
  }

  if (config.public.appEnv !== Environment.Pro) {
    ['x-cloudfront-viewer-city', 'x-cloudfront-viewer-country-name'].forEach((headerName) => {
      if (requestHeaders[headerName]) {
        headers[headerName] = requestHeaders[headerName];
      }
    });
  }

  const rawCookie = getRawCookie(requestEvent);

  if (rawCookie) {
    const cookies = parseRawCookie(requestEvent);

    const wishedCookies = ['access_token', 'id_token'];
    const availableCookies = wishedCookies.map((cookieName) => (cookies[cookieName] ? `${cookieName}=${cookies[cookieName]}` : null)).filter(Boolean);

    headers.cookie = availableCookies.join('; ');
  }

  return headers;
}

/**
 * @param nuxt
 * @returns
 */
function getDefaultApolloClient(): ApolloClient<NormalizedCacheObject> {
  const config = useRuntimeConfig();

  const refreshHeadersLink = new ApolloLink((operation, forward) => {
    operation.setContext(() => {
      const apolloHeaders = useApolloHeaders();
      return {
        headers: apolloHeaders
      };
    });

    return forward(operation);
  });

  const errorLink = onError(({ forward, graphQLErrors, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions, locations, message, path }) => {
        /* eslint-disable no-console */
        console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, code: ${extensions.code}`);
        /* eslint-enable no-console */
        const isArticle = path?.includes(GqlPathError.EDITORIAL_CONTENT) && operation?.variables?.type === 'article';
        if ((path?.includes(GqlPathError.INSPIRATION) || extensions.code === GqlErrorCode.NOT_FOUND) && !isArticle) {
          throw createError({ fatal: true, statusCode: 404 });
        }
      });
    }

    forward(operation);
  });

  const retryLink = new RetryLink({
    attempts: { max: 2 },
    delay: {
      initial: 200,
      jitter: true,
      max: 2000
    }
  });

  //  TODO: check if we can cache links in Envs and if it works good enough
  const cachedLinks = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test' ? [] : [apolloPersistedLink];

  const link = ApolloLink.from([
    refreshHeadersLink,
    errorLink,
    retryLink,
    ...cachedLinks,
    new HttpLink({
      credentials: 'same-origin',
      uri: (config.serverApolloUri as string) ?? (config.public.browserApolloUri as string)
    })
  ]);

  const cache = new InMemoryCache({
    typePolicies: {
      Restaurant: {
        fields: {
          availabilities: {
            merge(_, incoming) {
              return incoming;
            }
          }
        }
      }
    }
  });
  return new ApolloClient<NormalizedCacheObject>({
    cache,
    connectToDevTools: process.client && process.dev,
    link,
    ...(process.server ? { ssrMode: true } : { ssrForceFetchDelay: 1000 })
  });
}

export default defineNuxtPlugin<ApolloPlugin>(() => {
  const nuxt = useNuxtApp();

  const apolloClient = getDefaultApolloClient();

  nuxt.hook('app:rendered', () => {
    if (!nuxt.payload.data) {
      nuxt.payload.data = {};
    }

    nuxt.payload.data['apollo-cache'] = apolloClient.extract();
  });

  apolloClient.restore(JSON.parse(JSON.stringify(nuxt.payload.data?.['apollo-cache'] ?? '{}')));

  const clients: ApolloPluginClients = { default: apolloClient };

  const defaultClient = clients?.default || clients[Object.keys(clients)[0]];

  provideApolloClients(clients);
  nuxt.vueApp.provide(ApolloClients, clients);
  nuxt._apolloClients = clients;
  return {
    provide: {
      apollo: { clients, defaultClient }
    }
  };
});
