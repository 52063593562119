import { default as _healthcheckqMjAjBRMRdMeta } from "/usr/src/app/packages/front/pages/_healthcheck.vue?macro=true";
import { default as index1X0vrKfSl0Meta } from "/usr/src/app/packages/front/pages/[country]/[city]/index.vue?macro=true";
import { default as map8wOTQ5ucOsMeta } from "/usr/src/app/packages/front/pages/[country]/[city]/map.vue?macro=true";
import { default as indexEa87LY9OgXMeta } from "/usr/src/app/packages/front/pages/[country]/index.vue?macro=true";
import { default as _91bookingId_93d70tp4EgjCMeta } from "/usr/src/app/packages/front/pages/booking/confirmation/[restaurantCode]/[bookingId].vue?macro=true";
import { default as _91slug_93v27oadMCCzMeta } from "/usr/src/app/packages/front/pages/handler/[type]/[slug].vue?macro=true";
import { default as indexP8vSbcFTtkMeta } from "/usr/src/app/packages/front/pages/index.vue?macro=true";
import { default as indexe1hPKGfjkjMeta } from "/usr/src/app/packages/front/pages/inspiration/index.vue?macro=true";
import { default as _91slug_93cda7hsS9cOMeta } from "/usr/src/app/packages/front/pages/page/[slug].vue?macro=true";
import { default as indexONqda9W7VvMeta } from "/usr/src/app/packages/front/pages/point-of-interest/[slug]/index.vue?macro=true";
import { default as mapMgsKvsU1GiMeta } from "/usr/src/app/packages/front/pages/point-of-interest/[slug]/map.vue?macro=true";
import { default as _91restaurantCode_93jnYyn1kNyvMeta } from "/usr/src/app/packages/front/pages/restaurant/[restaurantCode].vue?macro=true";
import { default as indexeeTnp67SS3Meta } from "/usr/src/app/packages/front/pages/sitemap/index.vue?macro=true";
import { default as _91slug_93JQPweczVMQMeta } from "~/pages/handler/[type]/[slug].vue?macro=true";
export default [
  {
    name: _healthcheckqMjAjBRMRdMeta?.name ?? "_healthcheck___en",
    path: _healthcheckqMjAjBRMRdMeta?.path ?? "/en/_healthcheck",
    meta: _healthcheckqMjAjBRMRdMeta || {},
    alias: _healthcheckqMjAjBRMRdMeta?.alias || [],
    redirect: _healthcheckqMjAjBRMRdMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/_healthcheck.vue").then(m => m.default || m)
  },
  {
    name: _healthcheckqMjAjBRMRdMeta?.name ?? "_healthcheck___fr",
    path: _healthcheckqMjAjBRMRdMeta?.path ?? "/fr/_healthcheck",
    meta: _healthcheckqMjAjBRMRdMeta || {},
    alias: _healthcheckqMjAjBRMRdMeta?.alias || [],
    redirect: _healthcheckqMjAjBRMRdMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/_healthcheck.vue").then(m => m.default || m)
  },
  {
    name: index1X0vrKfSl0Meta?.name ?? "country-city___en",
    path: index1X0vrKfSl0Meta?.path ?? "/en/:country()/:city()",
    meta: index1X0vrKfSl0Meta || {},
    alias: index1X0vrKfSl0Meta?.alias || [],
    redirect: index1X0vrKfSl0Meta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/[country]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: index1X0vrKfSl0Meta?.name ?? "country-city___fr",
    path: index1X0vrKfSl0Meta?.path ?? "/fr/:country()/:city()",
    meta: index1X0vrKfSl0Meta || {},
    alias: index1X0vrKfSl0Meta?.alias || [],
    redirect: index1X0vrKfSl0Meta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/[country]/[city]/index.vue").then(m => m.default || m)
  },
  {
    name: map8wOTQ5ucOsMeta?.name ?? "country-city-map___en",
    path: map8wOTQ5ucOsMeta?.path ?? "/en/:country()/:city()/map",
    meta: map8wOTQ5ucOsMeta || {},
    alias: map8wOTQ5ucOsMeta?.alias || [],
    redirect: map8wOTQ5ucOsMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/[country]/[city]/map.vue").then(m => m.default || m)
  },
  {
    name: map8wOTQ5ucOsMeta?.name ?? "country-city-map___fr",
    path: map8wOTQ5ucOsMeta?.path ?? "/fr/:country()/:city()/map",
    meta: map8wOTQ5ucOsMeta || {},
    alias: map8wOTQ5ucOsMeta?.alias || [],
    redirect: map8wOTQ5ucOsMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/[country]/[city]/map.vue").then(m => m.default || m)
  },
  {
    name: indexEa87LY9OgXMeta?.name ?? "country___en",
    path: indexEa87LY9OgXMeta?.path ?? "/en/:country()",
    meta: indexEa87LY9OgXMeta || {},
    alias: indexEa87LY9OgXMeta?.alias || [],
    redirect: indexEa87LY9OgXMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: indexEa87LY9OgXMeta?.name ?? "country___fr",
    path: indexEa87LY9OgXMeta?.path ?? "/fr/:country()",
    meta: indexEa87LY9OgXMeta || {},
    alias: indexEa87LY9OgXMeta?.alias || [],
    redirect: indexEa87LY9OgXMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: _91bookingId_93d70tp4EgjCMeta?.name ?? "booking-confirmation-restaurantCode-bookingId___en",
    path: _91bookingId_93d70tp4EgjCMeta?.path ?? "/en/booking/confirmation/:restaurantCode()/:bookingId()",
    meta: _91bookingId_93d70tp4EgjCMeta || {},
    alias: _91bookingId_93d70tp4EgjCMeta?.alias || [],
    redirect: _91bookingId_93d70tp4EgjCMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/booking/confirmation/[restaurantCode]/[bookingId].vue").then(m => m.default || m)
  },
  {
    name: _91bookingId_93d70tp4EgjCMeta?.name ?? "booking-confirmation-restaurantCode-bookingId___fr",
    path: _91bookingId_93d70tp4EgjCMeta?.path ?? "/fr/booking/confirmation/:restaurantCode()/:bookingId()",
    meta: _91bookingId_93d70tp4EgjCMeta || {},
    alias: _91bookingId_93d70tp4EgjCMeta?.alias || [],
    redirect: _91bookingId_93d70tp4EgjCMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/booking/confirmation/[restaurantCode]/[bookingId].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93v27oadMCCzMeta?.name ?? "handler-type-slug___en",
    path: _91slug_93v27oadMCCzMeta?.path ?? "/en/handler/:type()/:slug()",
    meta: _91slug_93v27oadMCCzMeta || {},
    alias: _91slug_93v27oadMCCzMeta?.alias || [],
    redirect: _91slug_93v27oadMCCzMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/handler/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93v27oadMCCzMeta?.name ?? "handler-type-slug___fr",
    path: _91slug_93v27oadMCCzMeta?.path ?? "/fr/handler/:type()/:slug()",
    meta: _91slug_93v27oadMCCzMeta || {},
    alias: _91slug_93v27oadMCCzMeta?.alias || [],
    redirect: _91slug_93v27oadMCCzMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/handler/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexP8vSbcFTtkMeta?.name ?? "index___en",
    path: indexP8vSbcFTtkMeta?.path ?? "/en",
    meta: indexP8vSbcFTtkMeta || {},
    alias: indexP8vSbcFTtkMeta?.alias || [],
    redirect: indexP8vSbcFTtkMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexP8vSbcFTtkMeta?.name ?? "index___fr",
    path: indexP8vSbcFTtkMeta?.path ?? "/fr",
    meta: indexP8vSbcFTtkMeta || {},
    alias: indexP8vSbcFTtkMeta?.alias || [],
    redirect: indexP8vSbcFTtkMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1hPKGfjkjMeta?.name ?? "inspiration___en",
    path: indexe1hPKGfjkjMeta?.path ?? "/en/inspiration",
    meta: indexe1hPKGfjkjMeta || {},
    alias: indexe1hPKGfjkjMeta?.alias || [],
    redirect: indexe1hPKGfjkjMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/inspiration/index.vue").then(m => m.default || m)
  },
  {
    name: indexe1hPKGfjkjMeta?.name ?? "inspiration___fr",
    path: indexe1hPKGfjkjMeta?.path ?? "/fr/inspiration",
    meta: indexe1hPKGfjkjMeta || {},
    alias: indexe1hPKGfjkjMeta?.alias || [],
    redirect: indexe1hPKGfjkjMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/inspiration/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cda7hsS9cOMeta?.name ?? "page-slug___en",
    path: _91slug_93cda7hsS9cOMeta?.path ?? "/en/page/:slug()",
    meta: _91slug_93cda7hsS9cOMeta || {},
    alias: _91slug_93cda7hsS9cOMeta?.alias || [],
    redirect: _91slug_93cda7hsS9cOMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93cda7hsS9cOMeta?.name ?? "page-slug___fr",
    path: _91slug_93cda7hsS9cOMeta?.path ?? "/fr/page/:slug()",
    meta: _91slug_93cda7hsS9cOMeta || {},
    alias: _91slug_93cda7hsS9cOMeta?.alias || [],
    redirect: _91slug_93cda7hsS9cOMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/page/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexONqda9W7VvMeta?.name ?? "point-of-interest-slug___en",
    path: indexONqda9W7VvMeta?.path ?? "/en/point-of-interest/:slug()",
    meta: indexONqda9W7VvMeta || {},
    alias: indexONqda9W7VvMeta?.alias || [],
    redirect: indexONqda9W7VvMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/point-of-interest/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexONqda9W7VvMeta?.name ?? "point-of-interest-slug___fr",
    path: indexONqda9W7VvMeta?.path ?? "/fr/point-of-interest/:slug()",
    meta: indexONqda9W7VvMeta || {},
    alias: indexONqda9W7VvMeta?.alias || [],
    redirect: indexONqda9W7VvMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/point-of-interest/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: mapMgsKvsU1GiMeta?.name ?? "point-of-interest-slug-map___en",
    path: mapMgsKvsU1GiMeta?.path ?? "/en/point-of-interest/:slug()/map",
    meta: mapMgsKvsU1GiMeta || {},
    alias: mapMgsKvsU1GiMeta?.alias || [],
    redirect: mapMgsKvsU1GiMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/point-of-interest/[slug]/map.vue").then(m => m.default || m)
  },
  {
    name: mapMgsKvsU1GiMeta?.name ?? "point-of-interest-slug-map___fr",
    path: mapMgsKvsU1GiMeta?.path ?? "/fr/point-of-interest/:slug()/map",
    meta: mapMgsKvsU1GiMeta || {},
    alias: mapMgsKvsU1GiMeta?.alias || [],
    redirect: mapMgsKvsU1GiMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/point-of-interest/[slug]/map.vue").then(m => m.default || m)
  },
  {
    name: _91restaurantCode_93jnYyn1kNyvMeta?.name ?? "restaurant-restaurantCode___en",
    path: _91restaurantCode_93jnYyn1kNyvMeta?.path ?? "/en/restaurant/:restaurantCode()",
    meta: _91restaurantCode_93jnYyn1kNyvMeta || {},
    alias: _91restaurantCode_93jnYyn1kNyvMeta?.alias || [],
    redirect: _91restaurantCode_93jnYyn1kNyvMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/restaurant/[restaurantCode].vue").then(m => m.default || m)
  },
  {
    name: _91restaurantCode_93jnYyn1kNyvMeta?.name ?? "restaurant-restaurantCode___fr",
    path: _91restaurantCode_93jnYyn1kNyvMeta?.path ?? "/fr/restaurant/:restaurantCode()",
    meta: _91restaurantCode_93jnYyn1kNyvMeta || {},
    alias: _91restaurantCode_93jnYyn1kNyvMeta?.alias || [],
    redirect: _91restaurantCode_93jnYyn1kNyvMeta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/restaurant/[restaurantCode].vue").then(m => m.default || m)
  },
  {
    name: indexeeTnp67SS3Meta?.name ?? "sitemap___en",
    path: indexeeTnp67SS3Meta?.path ?? "/en/sitemap",
    meta: indexeeTnp67SS3Meta || {},
    alias: indexeeTnp67SS3Meta?.alias || [],
    redirect: indexeeTnp67SS3Meta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: indexeeTnp67SS3Meta?.name ?? "sitemap___fr",
    path: indexeeTnp67SS3Meta?.path ?? "/fr/sitemap",
    meta: indexeeTnp67SS3Meta || {},
    alias: indexeeTnp67SS3Meta?.alias || [],
    redirect: indexeeTnp67SS3Meta?.redirect,
    component: () => import("/usr/src/app/packages/front/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JQPweczVMQMeta?.name ?? "collection-slug___en",
    path: _91slug_93JQPweczVMQMeta?.path ?? "/en/collection/:slug",
    meta: _91slug_93JQPweczVMQMeta || {},
    alias: _91slug_93JQPweczVMQMeta?.alias || [],
    redirect: _91slug_93JQPweczVMQMeta?.redirect,
    component: () => import("~/pages/handler/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JQPweczVMQMeta?.name ?? "collection-slug___fr",
    path: _91slug_93JQPweczVMQMeta?.path ?? "/fr/collection/:slug",
    meta: _91slug_93JQPweczVMQMeta || {},
    alias: _91slug_93JQPweczVMQMeta?.alias || [],
    redirect: _91slug_93JQPweczVMQMeta?.redirect,
    component: () => import("~/pages/handler/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JQPweczVMQMeta?.name ?? "offer-slug___en",
    path: _91slug_93JQPweczVMQMeta?.path ?? "/en/offer/:slug",
    meta: _91slug_93JQPweczVMQMeta || {},
    alias: _91slug_93JQPweczVMQMeta?.alias || [],
    redirect: _91slug_93JQPweczVMQMeta?.redirect,
    component: () => import("~/pages/handler/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JQPweczVMQMeta?.name ?? "offer-slug___fr",
    path: _91slug_93JQPweczVMQMeta?.path ?? "/fr/offer/:slug",
    meta: _91slug_93JQPweczVMQMeta || {},
    alias: _91slug_93JQPweczVMQMeta?.alias || [],
    redirect: _91slug_93JQPweczVMQMeta?.redirect,
    component: () => import("~/pages/handler/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JQPweczVMQMeta?.name ?? "article-slug___en",
    path: _91slug_93JQPweczVMQMeta?.path ?? "/en/article/:slug",
    meta: _91slug_93JQPweczVMQMeta || {},
    alias: _91slug_93JQPweczVMQMeta?.alias || [],
    redirect: _91slug_93JQPweczVMQMeta?.redirect,
    component: () => import("~/pages/handler/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93JQPweczVMQMeta?.name ?? "article-slug___fr",
    path: _91slug_93JQPweczVMQMeta?.path ?? "/fr/article/:slug",
    meta: _91slug_93JQPweczVMQMeta || {},
    alias: _91slug_93JQPweczVMQMeta?.alias || [],
    redirect: _91slug_93JQPweczVMQMeta?.redirect,
    component: () => import("~/pages/handler/[type]/[slug].vue").then(m => m.default || m)
  }
]