import { urlQueryParams } from '~/helpers/urlQueryParams';

export default defineNuxtRouteMiddleware(async (to) => {
  const config = useRuntimeConfig();
  const defaultLang = config.public.i18n.defaultLocale;
  const { redirectToHomepage } = useCountry();
  const supportedLanguages = Object.keys(config.public.i18n.locales);

  const pathSegments = to.path.split('/');
  const pathLang = pathSegments[1];

  if (supportedLanguages.some((language) => to.name === `index___${language}`)) {
    return navigateTo(await redirectToHomepage(), {
      redirectCode: 302
    });
  } else if (!supportedLanguages.includes(pathLang) && pathLang !== '404') {
    pathSegments[1] = defaultLang;
    let newPath = pathSegments.join('/');

    if (Object.keys(to.query).length) {
      newPath += `?${urlQueryParams(to.query)}`;
    }

    useHead({
      meta: [
        {
          content: 'noindex, nofollow',
          name: 'robots'
        }
      ]
    });

    return navigateTo(newPath, { redirectCode: 302 });
  }
});
