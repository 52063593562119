import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45clean_45encoded_45url_45global from "/usr/src/app/packages/front/middleware/01.clean-encoded-url.global.ts";
import _02_45page_45redirect_45global from "/usr/src/app/packages/front/middleware/02.page-redirect.global.ts";
import _03_45webview_45global from "/usr/src/app/packages/front/middleware/03.webview.global.ts";
import _04_45language_45redirect_45global from "/usr/src/app/packages/front/middleware/04.language-redirect.global.ts";
import persist_45query_45params_45global from "/usr/src/app/packages/front/middleware/persist-query-params.global.ts";
import refresh_45token_45global from "/usr/src/app/packages/front/middleware/refresh-token.global.ts";
export const globalMiddleware = [
  validate,
  _01_45clean_45encoded_45url_45global,
  _02_45page_45redirect_45global,
  _03_45webview_45global,
  _04_45language_45redirect_45global,
  persist_45query_45params_45global,
  refresh_45token_45global
]
export const namedMiddleware = {}