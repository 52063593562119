import { UTCDate } from '@date-fns/utc';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears
} from 'date-fns';
import { ComposerTranslation } from 'vue-i18n';

import { useDateFormat } from '../composables/useDateFormat';

export const formatSinceDate = (t: ComposerTranslation, originalDate?: string): string => {
  if (!originalDate) return '';

  const date = new Date(originalDate);
  const now = new Date();

  const seconds = differenceInSeconds(now, date);
  if (seconds < 60) {
    return t('sinceDates.seconds', { n: seconds });
  }

  const minutes = differenceInMinutes(now, date);
  if (minutes <= 60) {
    return t('sinceDates.minutes', { n: minutes });
  }

  const hours = differenceInHours(now, date);
  if (hours <= 24) {
    return t('sinceDates.hours', { n: hours });
  }

  const days = differenceInDays(now, date);
  if (days <= 7) {
    return t('sinceDates.days', { n: days });
  }

  const weeks = differenceInWeeks(now, date);
  if (weeks <= 4) {
    return t('sinceDates.weeks', { n: weeks });
  }

  const months = differenceInMonths(now, date);
  if (months <= 12) {
    return t('sinceDates.months', { n: months });
  }

  const years = differenceInYears(now, date);
  return t('sinceDates.years', { n: years });
};

// Converts date from YYYY-MM-DD to UTC date
export const formatToUTC = (date: string, dayOffset?: number) => {
  const [year, month, day] = date.split('-').map((part) => Number(part)) as [number, number, number];
  const incrementedDay = dayOffset ? day + dayOffset : day;
  return new UTCDate(year, month - 1, incrementedDay);
};

// Returns today's UTC date (format YYYY-MM-DD)
export const newUTCToday = () => {
  const today = new Date();
  const year = today.getUTCFullYear();
  const month = today.getUTCMonth() + 1;
  const day = today.getUTCDate();
  return `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`;
};

// Returns specific UTC date (format YYYY-MM-DD)
export const newUTCDate = (date?: string) => {
  if (!date) return newUTCToday();

  const today = new Date(date);
  const year = today.getUTCFullYear();
  const month = today.getUTCMonth() + 1;
  const day = today.getUTCDate();
  return `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`;
};

// Returns date YYYY-MM-DD full digits
export const dateValue = (dayIndex: number, initialDate: string) => {
  const date = formatToUTC(initialDate as string, dayIndex);
  return useDateFormat(date, 'y-MM-dd');
};

// Returns today, tomorrow or date DD/MM/YYYY full digits
export const dateClassic = (initialDate: string) => {
  const date = new UTCDate(initialDate);
  return useDateFormat(date, 'dd/MM/y');
};

// Returns 2 digits day, 3 letters month
export const dateMonthDay = (dayIndex: number, initialDate: string) => {
  const date = formatToUTC(initialDate as string, dayIndex);
  return useDateFormat(date, 'dd MMM');
};

// Returns today, tomorrow or day abbr
export const dateName = (dayIndex: number, initialDate: string) => {
  const date = formatToUTC(initialDate as string, dayIndex);
  return useDateFormat(date, 'E');
};

// Returns number of days difference between two dates
export const daysDifference = (secondDate: string, firstDate?: string) => {
  return differenceInDays(new Date(newUTCDate(secondDate)), new Date(firstDate ? newUTCDate(firstDate) : newUTCToday()));
};

export const formatDateToTZID = (date: Date): string => {
  return date.toISOString().replace(/[-:]/g, '').split('.')[0];
};
