import { LocationQuery } from 'vue-router';

// eslint-disable-next-line sonarjs/cognitive-complexity
export default defineNuxtRouteMiddleware((to, from) => {
  const compareFromAndToQueryKeys = (fromQuery: LocationQuery, toQuery: LocationQuery): boolean => {
    const fromKeys = Object.keys(fromQuery);
    const toKeys = Object.keys(toQuery);
    return fromKeys.length === toKeys.length && fromKeys.every((key) => toQuery[key] === fromQuery[key]);
  };

  if (
    ((from?.query?.webView === 'true' && !to.query.webView) ||
      (!from.query.book && to.query.book === 'true') ||
      !compareFromAndToQueryKeys(from.query, to.query)) &&
    !to.redirectedFrom
  ) {
    const navigateObject: Record<string, string | undefined> = {
      ...from.query,
      ...to.query,
      ...(from.query.webView === 'true' ? { webView: 'true' } : {})
    };

    if (from.query.webView === 'true') {
      navigateObject.webView = 'true';
    }

    if (navigateObject.book && from.query.book && !to.query.book) {
      delete navigateObject.book;
    }

    if (navigateObject.slot && from.query.slot && !to.query.slot) {
      delete navigateObject.slot;
    }

    return navigateTo({
      ...to,
      query: navigateObject
    });
  }
});
