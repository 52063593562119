/* eslint-disable sonarjs/no-duplicate-string */
export const LoyaltyBanner = {
  program: {
    ALL: 'All',
    AP: 'Accor Plus'
  },
  userStatus: {
    LOGGED_IN: 'User logged in',
    LOGGED_OUT: 'User logged out'
  }
};

export const Partners: Array<string> = ['ZENCHEF', 'SEVENROOMS'];

export const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

export const timeZones = {
  FR: 'Europe/Paris'
};

export const DEFAULT_SEARCH_RADIUS = 50000;

export const DEFAULT_TIMEZONE = 'Europe/Paris';

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const BOOKING_FUNNEL_DEFAULT = 2;

export const INCLUDE_FILTERS_KEYS = [
  'FOOD_PREFERENCES',
  'STYLE_OF_FOOD',
  'THEMATIC',
  'AVERAGE_RATING',
  'OFFER_AND_LOYALTY',
  'available',
  'includeFilters',
  'maxPrice',
  'minPrice'
];

export const OLD_ARTICLES_TO_REDIRECT: Record<string, string> = {
  '/en/article/an-interview-with-quentin-lechat': 'https://all.accor.com/a/en/limitless/thematics/food-drink/quentin-lechat.html',

  '/en/article/recipe-for-ceviche-style-beef': 'https://all.accor.com/a/en/limitless/thematics/food-drink/beef-ceviche.html',

  '/en/article/recipe-for-da-vittorio-style-paccheri-pasta': 'https://all.accor.com/a/en/limitless/thematics/food-drink/paccheri-pasta-recipe.html',

  '/fr/article/10-astuces-pour-un-brunch-reussi': 'https://all.accor.com/a/fr/limitless/thematics/food-drink/composition-brunch-parfait.html',

  '/fr/article/brunch-anglais': 'https://all.accor.com/a/fr/limitless/thematics/food-drink/petit-dejeuner-anglais.html',

  '/fr/article/recette-de-paccheri-facon-da-vittorio': 'https://all.accor.com/a/fr/limitless/thematics/food-drink/recette-paccheri.html'
};

export const OLD_CITY_TO_REDIRECT: Record<string, string> = {
  '/en/bahrain/zallaq/map': '/en/bahrain/manama/map',
  '/en/france/avignon-sorgues-2/map': '/en/france/avignon/map',
  '/en/france/bezons/map': '/en/france',
  '/en/france/carquefou/map': '/en/france',
  '/en/france/corbeil-essonnes/map': '/en/france',
  '/en/france/courbevoie-2/map': '/en/france/courbevoie/map',
  '/en/france/jurancon/map': '/en/france',
  '/en/france/lalpe-dhuez/map': '/en/france/grenoble/map',
  '/en/france/le-lavandou/map': '/en/france/hyeres/map',
  '/en/france/lens/map': '/en/france/noyelles-godault/map',
  '/en/france/les-ulis/map': '/en/france/saclay/map',
  '/en/france/levallois-perret/map': '/en/france/paris/map',
  '/en/france/lille-marcq-en-baroeul/map': '/en/france/marcq-en-baroeul/map',
  '/en/france/mandelieu-la-napoule-2/map': '/en/france/mandelieu-la-napoule/map',
  '/en/france/marne-la-vallee-collegien/map': '/en/france',
  '/en/france/marseille-marignane/map': '/en/france/marseille/map',
  '/en/france/massy-palaiseau/map': '/en/france/palaiseau/map',
  '/en/france/meriadeck/map': '/en/france/bordeaux/map',
  '/en/france/nuits-saint-georges/map': '/en/france/beaune/map',
  '/en/france/orly-2/map': '/en/france',
  '/en/france/orly-paray-vieille-poste/map': '/en/france',
  '/en/france/orsay/map': '/en/france/saclay/map',
  '/en/france/poitiers/map': '/en/france',
  '/en/france/roissy-en-france/map': '/en/france/roissy/map',
  '/en/france/saint-laurent-du-var/map': '/en/france/nice/map',
  '/en/france/saint-raphael/map': '/en/france/cannes/map',
  '/en/france/saint-trojan-les-bains/map': '/en/france',
  '/en/france/toulon/map': '/en/france/hyeres/map',
  '/en/france/tournus/map': '/en/france/chalon-sur-saone/map',
  '/en/greece/spata/map': '/en/greece/athens-spata/map',
  '/en/italy/cagliari/map': '/en/italy',
  '/en/italy/milan/map': '/en/italy/vizzola-ticino/map',
  '/en/italy/milan-malpensa/map': '/en/italy/vizzola-ticino/map',
  '/en/italy/venice-2/map': '/en/italy/venice/map',
  '/en/italy/villasimius/map': '/en/italy',
  '/en/monaco/monte-carlo/map': '/en/monaco/monaco/map',
  '/en/portugal/lisbon/map': '/en/portugal/lisboa/map',
  '/en/portugal/oporto/map': '/en/portugal/porto/map',
  '/fr/arabie-saoudite/jeddah/map': '/fr/arabie-saoudite/djeddah/map',
  '/fr/arabie-saoudite/riyad/map': '/fr/arabie-saoudite/riyadh/map',
  '/fr/bahrain/zallaq/map': '/fr/bahrain/manama/map',
  '/fr/emirats-arabes-unis/abu-dhabi/map': '/fr/emirats-arabes-unis/abou-dhabi/map',
  '/fr/france/avignon-sorgues-2/map': '/fr/france/avignon/map',
  '/fr/france/bezons/map': '/fr/france',
  '/fr/france/cannes/map': '/fr/france/cannes-la-bocca/map',
  '/fr/france/carquefou/map': '/fr/france',
  '/fr/france/chambray-les-tours-chantilly/map': '/fr/france/chambray-les-tours/map',
  '/fr/france/corbeil/map': '/fr/france',
  '/fr/france/corbeil-essonnes/map': '/fr/france',
  '/fr/france/courbevoie-2/map': '/fr/france/courbevoie/map',
  '/fr/france/jurancon/map': '/fr/france',
  '/fr/france/la-defense-courbevoie/map': '/fr/france/paris-la-defense-courbevoie/map',
  '/fr/france/lalpe-dhuez/map': '/fr/france/grenoble/map',
  '/fr/france/le-lavandou/map': '/fr/france/hyeres/map',
  '/fr/france/le-touquet/map': '/fr/france',
  '/fr/france/lens/map': '/fr/france/noyelles-godault/map',
  '/fr/france/les-ulis/map': '/fr/france/saclay/map',
  '/fr/france/levallois-perret/map': '/fr/france/paris/map',
  '/fr/france/mandelieu-la-napoule-2/map': '/fr/france/mandelieu-la-napoule/map',
  '/fr/france/marne-la-vallee-collegien/map': '/fr/france',
  '/fr/france/meriadeck/map': '/fr/france/bordeaux/map',
  '/fr/france/nuits-saint-georges/map': '/fr/france/beaune/map',
  '/fr/france/orly-2/map': '/fr/france',
  '/fr/france/orsay/map': '/fr/france/saclay/map',
  '/fr/france/paris-gentilly/map': '/fr/france/gentilly/map',
  '/fr/france/poitiers/map': '/fr/france',
  '/fr/france/saint-laurent-du-var/map': '/fr/france/nice/map',
  '/fr/france/saint-raphael/map': '/fr/france/cannes-la-bocca/map',
  '/fr/france/saint-trojan-les-bains/map': '/fr/france',
  '/fr/france/toulon/map': '/fr/france/hyeres/map',
  '/fr/france/tournus/map': '/fr/france/chalon-sur-saone/map',
  '/fr/grece/athenes-2/map': '/fr/grece/spata/map',
  '/fr/italie/cagliari/map': '/fr/italie',
  '/fr/italie/milan/map': '/fr/italie/vizzola-ticino/map',
  '/fr/italie/milan-malpensa/map': '/fr/italie/vizzola-ticino/map',
  '/fr/italie/venise-2/map': '/fr/italie/venise/map',
  '/fr/italie/villasimius/map': '/fr/italie',
  '/fr/monaco/monte-carlo/map': '/fr/monaco',
  '/fr/portugal/lisbonne/map': '/fr/portugal/lisboa/map',
  '/fr/royaume-uni/edimbourg/map': '/fr/royaume-uni/edinburgh/map'
};

export const OLD_ARTICLE_TO_REDIRECT_TO_HP: Array<string> = [
  '/en/article/quindici-an-authentic-trattoria',
  '/fr/article/quindici-trattoria-authentique',
  '/en/article/ten-terraces-for-al-fresco-meals-2',
  '/en/article/coffee-culture-in-italy-france-and-spain',
  '/en/article/10-amazing-restaurants-with-the-best-views-in-france-2',
  '/en/article/tata-family-style-dishes-inspired-by-the-mediterranean',
  '/en/article/dining-and-mixology-at-fairmont-elegance-and-refinement-at-their-best',
  '/en/article/dining-at-mama-shelter-a-blend-of-conviviality-and-creativity',
  '/en/article/dining-highlights',
  '/en/article/afternoon-tea-indulgence-in-singapore',
  '/en/article/wine-tours-in-france-italy-spain-and-portugal-2',
  '/fr/article/10-terrasses-de-reve-en-france-en-italie-et-en-espagne',
  '/en/article/light-lunches-for-hot-summer-days-2',
  '/en/article/brunch-around-the-world-2',
  '/en/article/citrus-cheesecake',
  '/en/article/foodie-ideas-for-la-rentree',
  '/fr/article/lart-du-cafe-en-italie-en-france-et-en-espagne',
  '/fr/article/restaurants-avec-terrasse-les-plus-belles-vues-de-france',
  '/fr/article/t-a-t-a-cuisine-familiale-aux-influences-mediterraneennes',
  '/fr/article/la-restauration-chez-fairmont-elegance-et-raffinement-au-rendez-vous',
  '/en/article/breakfast-traditions-in-southern-europe',
  '/en/article/chocolate-eclair-recipe',
  '/en/article/snacking-in-france-italy-and-spain',
  '/fr/article/escapades-viticoles-en-europe-du-sud',
  '/fr/article/dining-highlights',
  '/en/article/rencontre-avec-la-cheffe-anais-foray',
  '/en/article/singapore-swing-cocktail',
  '/en/article/southern-european-christmas-foods',
  '/en/article/three-good-reasons-to-celebrate-christmas-in-a-restaurant',
  '/en/article/the-king-cakes-of-southern-europe',
  '/en/article/five-trending-foods-to-look-out-for-in-2023',
  '/en/article/getting-back-into-the-habit-of-restaurant-dining',
  '/en/article/the-health-benefits-of-olive-oil',
  '/en/article/easy-chocolate-fondant-molten-chocolate-lava-cake',
  '/en/article/the-art-of-italian-gastronomy-and-cuisine',
  '/en/article/homemade-iced-cappuccino',
  '/en/article/pearl-berries-a-vodka-and-berry-cocktail',
  '/en/article/yuzu-the-trendy-citrus-fruit-from-japan',
  '/en/article/rhubarb-tart-with-goat-milk-cremeux',
  '/en/article/duck-breast-tartare-with-seaweed-recipe',
  '/en/article/scorpionfish-tagine-recipe',
  '/en/article/skip-the-bread-following-a-gluten-free-diet',
  '/en/article/five-easy-ways-to-spring-clean-your-diet',
  '/en/article/giving-the-after-school-snack-a-healthy-makeover',
  '/en/article/the-tastes-of-summer-on-your-plate',
  '/en/article/superfoods-supercharge-your-diet-for-la-rentree',
  '/en/article/cuisine-and-longevity-how-to-eat-for-a-longer-life',
  '/en/article/making-the-most-of-a-lactose-free-diet',
  '/en/article/recipe-for-pumpkin-risotto',
  '/en/article/easter-culinary-traditions-in-southern-europe',
  '/en/article/sea-bass-tartare-with-seaweed',
  '/en/article/four-tips-for-sustainable-eating-and-dining',
  '/en/article/crispy-italian-style-salmon-recipe',
  '/en/article/the-art-of-french-gastronomy-and-cuisine',
  '/en/article/the-art-of-spanish-gastronomy-and-cuisine',
  '/en/article/craft-beer-takes-over-southern-europe',
  '/en/article/kombucha-the-resurgence-of-a-traditional-tonic',
  '/en/article/fusion-burgers-a-global-phenomenon-2',
  '/en/article/spiritourism-closer-encounters-with-french-spirits',
  '/en/article/spaghetti-a-la-carbonara',
  '/en/article/savourez-un-automne-gourmand-et-vitamine-avec-les-fruits-et-legumes-de-saison',
  '/en/article/red-mullet-in-the-sun',
  '/en/article/poulpe-roti-aux-herbes-et-puree-de-patates-douces-a-lencre-de-seiche',
  '/en/article/normandiny-a-calvados-and-cider-cocktail',
  '/fr/article/la-restauration-au-mama-shelter-un-melange-de-convivialite-et-de-generosite',
  '/en/article/food-trucks-the-new-way-to-eat-in-europe',
  '/en/article/delicious-southern-european-street-food',
  '/en/article/how-southern-europe-celebrates-halloween',
  '/en/article/sign-up-for-the-urban-farming-revolution',
  '/en/article/an-italian-take-on-the-poke-bowl',
  '/en/article/pain-au-lait-the-taste-of-french-childhood',
  '/en/article/the-burger-a-global-dish-with-international-origins',
  '/en/article/sweet-burgers-a-revolutionary-take-on-a-culinary-classic-2',
  '/en/article/the-art-of-the-cocktail-in-southern-europe',
  '/en/article/novotel-restaurants-gourmet-burgers-2',
  '/en/article/what-to-expect-at-a-dubai-brunch',
  '/en/article/five-flourishing-food-trends-in-dubai',
  '/en/article/blissful-brunches-in-singapore',
  '/en/article/its-5-oclock-somewhere',
  '/en/article/jaan-by-kirk-westaway',
  '/en/article/why-have-hot-sauces-become-so-popular-in-cooking',
  '/fr/article/gastronomie-parisienne-une-diversite-toujours-renouvelee',
  '/fr/article/afternoon-tea-indulgence-in-singapore',
  '/en/article/duck-breast-with-roasted-polenta-sangria-reduction-and-peach-recipe',
  '/en/article/n-15-cocktail-rum-rye-and-vintage-vibes',
  '/en/article/mocktails-and-other-non-alcoholic-mixed-drinks',
  '/en/article/chicken-caesar-salad',
  '/en/article/vegan-pasta-carbonara',
  '/en/article/breaded-jammy-eggs-with-mushroom-espuma',
  '/en/article/the-spritzouille-an-easy-to-make-cocktail-recipe',
  '/en/article/winter-fruits-and-vegetables-to-savour-from-starters-to-desserts',
  '/fr/article/idees-foodie-pour-la-rentree',
  '/en/article/homemade-salt-cured-foie-gras',
  '/fr/article/plats-d-ete-des-recettes-pleines-de-fraicheur',
  '/en/article/deconstructed-strawberry-tart-with-matcha-cream-recipe',
  '/en/article/signature-burger-recipe',
  '/en/article/artichoke-and-lobster-salad-recipe',
  '/fr/article/cheesecake-aux-agrumes',
  '/en/article/raspberry-pistachio-charlotte-recipe',
  '/en/article/english-style-brunch',
  '/fr/article/les-galettes-et-gateaux-des-rois-d-europe-du-sud',
  '/fr/article/cinq-tendances-alimentaires-a-surveiller-en-2023',
  '/fr/article/reprenez-l-habitude-d-aller-au-restaurant',
  '/fr/article/les-bienfaits-de-l-huile-d-olive-pour-la-sante',
  '/fr/article/cappuccino-glace-maison',
  '/fr/article/pearl-berries-cocktail-de-vodka-et-fruits-rouges',
  '/fr/article/yuzu-l-agrume-tendance-venu-du-japon',
  '/fr/article/tout-sur-le-brunch',
  '/fr/article/les-astuces-pour-adopter-le-jardinage-ou-l-agriculture-urbaine',
  '/fr/article/le-poke-bowl-a-l-italienne',
  '/fr/article/le-pain-au-lait-de-mon-enfance-2',
  '/fr/article/le-burger-un-plat-international-aux-origines-cosmopolites',
  '/fr/article/le-burger-en-douceur',
  '/fr/article/l-art-du-cocktail-en-europe-du-sud',
  '/fr/article/l-art-du-burger-au-gourmet-bar-by-novotel',
  '/fr/article/l-art-de-la-gastronomie-et-de-la-cuisine-italienne',
  '/fr/article/rencontre-avec-la-cheffe-anais-foray',
  '/fr/article/rencontre-avec-quentin-lechat',
  '/fr/article/recette-de-risotto-au-potiron',
  '/fr/article/tartare-de-bar-aux-algues',
  '/fr/article/salade-cesar-au-poulet',
  '/fr/article/pates-facon-carbonara-vegan',
  '/fr/article/oeuf-mollet-croustillant-et-espuma-de-champignons',
  '/fr/article/le-spritzouille-une-recette-de-cocktail-facile-a-realiser',
  '/fr/article/les-specialites-de-noel-deurope-du-sud',
  '/fr/article/les-traditions-de-petit-dejeuner-en-europe-du-sud',
  '/fr/article/eclair-au-chocolat',
  '/fr/article/les-en-cas-en-france-en-italie-et-en-espagne',
  '/fr/article/traditions-culinaires-de-paques-en-europe-du-sud',
  '/fr/article/pourquoi-les-sauces-piquantes-sont-elles-devenues-si-populaires-en-cuisine',
  '/fr/article/jaan-par-kirk-westaway-conserve-ses-2-etoiles-michelin',
  '/fr/article/5-oclock-somewhere',
  '/fr/article/blissful-brunches-in-singapore',
  '/fr/article/un-gout-de-tradition-emiratie-des-lokmas-maison',
  '/fr/article/a-quoi-s-attendre-lors-d-un-brunch-a-dubai',
  '/fr/article/cinq-tendances-alimentaires-florissantes-a-dubai',
  '/fr/article/veau-a-la-milanese-et-linguine-maison',
  '/fr/article/tarte-rhubarbe-et-cremeux-chevre',
  '/fr/article/tartare-de-magret-aux-algues',
  '/fr/article/tajine-de-rascasse',
  '/fr/article/spiritourisme-plongez-dans-l-univers-des-spiritueux-francais',
  '/fr/article/spaghetti-a-la-carbonara',
  '/fr/article/savourez-un-automne-gourmand-et-vitamine-avec-les-fruits-et-legumes-de-saison',
  '/fr/article/les-celebrations-d-halloween-en-europe-du-sud',
  '/fr/article/cocktail-singapore-swing',
  '/fr/article/cocktail-n-15-rhum-rye-et-parfum-vintage',
  '/fr/article/normandiny-cocktail-au-calvados-et-cidre',
  '/fr/article/mocktails-et-boissons-sans-alcool',
  '/fr/article/la-biere-artisanale-a-la-conquete-de-l-europe-du-sud',
  '/fr/article/kombucha-la-renaissance-d-une-boisson-energisante-traditionnelle',
  '/fr/article/3-bonnes-raisons-de-feter-noel-au-restaurant',
  '/fr/article/rouget-ensoleille',
  '/fr/article/repenser-le-gouter-des-enfants-de-facon-plus-saine',
  '/fr/article/poulpe-roti-aux-herbes-et-puree-de-patates-douces-a-l-encre-de-seiche',
  '/fr/article/pate-en-croute-aux-champignons-et-pistaches',
  '/fr/article/nem-sucre',
  '/fr/article/fondant-mi-cuit-au-chocolat-facile-et-rapide',
  '/en/article/douceur-de-lait-and-cacao-2',
  '/fr/article/4-astuces-pour-manger-de-facon-plus-durable',
  '/fr/article/croustillant-de-saumon-a-l-italienne',
  '/fr/article/daube-de-poulpe-a-la-provencale-et-frites-de-panisse-aux-olives',
  '/fr/article/comment-suivre-un-regime-sans-gluten',
  '/fr/article/cinq-idees-faciles-pour-faire-le-menage-de-printemps-dans-votre-regime-alimentaire',
  '/fr/article/mettez-les-saveurs-estivales-au-menu',
  '/fr/article/les-super-aliments-ou-comment-faire-le-plein-d-energie-pour-la-rentree',
  '/fr/article/gastronomie-et-longevite-la-meilleure-facon-de-manger-pour-vivre-plus-longtemps',
  '/fr/article/comment-adopter-un-regime-sans-lactose-en-toute-facilite',
  '/en/article/yuzu-cheesecake-2',
  '/en/article/apple-flognarde-recipe',
  '/fr/article/carpaccio-de-boeuf-facon-tataki-et-caviar-de-moutarde',
  '/fr/article/magret-de-canard-et-sa-polenta-rotie-jus-de-sangria-et-peche',
  '/en/article/apero-tonic-cocktail',
  '/fr/article/les-fruits-et-legumes-d-hiver-a-savourer-de-l-entree-au-dessert',
  '/fr/article/les-delices-de-la-street-food-en-europe-du-sud',
  '/en/article/a-taste-of-emirati-tradition-homemade-luqaimat',
  '/en/article/quasi-black-forest-cake-2',
  '/en/article/the-rise-of-conscious-drinking-in-europe',
  '/en/article/how-beaujolais-nouveau-took-over-the-world',
  '/en/article/recipe-for-prince-chocolat',
  '/en/article/food-ethics-vegetarianism-veganism-and-slow-food-2',
  '/en/article/plant-based-meat-and-cheese-alternatives',
  '/en/article/raspberry-bubbles-a-zero-alcohol-cocktail',
  '/en/article/australian-style-brunch',
  '/en/article/john-dory-in-the-sun-recipe',
  '/en/article/blood-orange-cremeux-with-crispy-tonka',
  '/en/article/ouakari-cocktail',
  '/en/article/french-fashion-cocktail',
  '/fr/article/food-trucks-la-nouvelle-facon-de-se-regaler-en-europe',
  '/fr/article/recette-du-burger-signature-d-olivier-sebastien',
  '/fr/article/fusion-burger-le-tour-du-monde-des-burgers',
  '/fr/article/l-art-de-la-cuisine-et-de-la-gastronomie-espagnole',
  '/fr/article/l-art-de-la-cuisine-et-gastronomie-francaise',
  '/en/article/recipe-for-the-classic-umami-burger',
  '/en/article/mothers-day-special-singapore',
  '/fr/article/recette-de-la-charlotte-pistache-framboise-d-olivier-sebastien',
  '/fr/article/recette-de-tarte-aux-fraises-et-creme-matcha-destructuree-de-willy-camboulin',
  '/fr/article/recette-salade-d-artichaut-au-homard-vinaigrette-aux-algues',
  '/fr/article/foie-gras-mi-cuit-au-sel-fait-maison',
  '/fr/article/flognarde-aux-pommes',
  '/fr/article/filets-de-sandre-au-beurre-nantais-et-mousseline-de-chou-fleur',
  '/fr/article/recette-du-prince-chocolat',
  '/fr/article/recette-de-saint-pierre-au-soleil',
  '/fr/article/recette-de-l-umami-burger-classic',
  '/fr/article/recette-de-boeuf-comme-un-ceviche',
  '/fr/article/la-recette-du-chef-paul-smart-souffle-a-la-framboise',
  '/fr/article/bulle-de-framboise-recette-de-cocktail-sans-alcool',
  '/fr/article/douceur-de-lait-et-cacao-2',
  '/fr/article/cremeux-d-orange-sanguine-et-croustillant-tonka',
  '/fr/article/comme-une-foret-noire-3',
  '/fr/article/cheesecake-au-yuzu',
  '/fr/article/chaussons-d-agneau-de-pauillac',
  '/fr/article/cocktail-ouakari',
  '/fr/article/cocktail-apero-tonic',
  '/fr/article/restauration-rapide-ethique-et-saine-2',
  '/fr/article/mothers-day-special-singapore',
  '/fr/article/alcool-vers-une-consommation-plus-raisonnee-en-europe',
  '/fr/article/brunch-a-l-australienne',
  '/fr/article/cocktail-french-fashion',
  '/fr/article/alternatives-vegetales-a-la-viande-et-au-fromage',
  '/fr/article/comment-le-beaujolais-nouveau-est-devenu-une-star-internationale'
];

export const OLD_CITY_TO_REMOVE: Array<string> = [
  '/en/france/meylan-2/map',
  '/en/france/ajaccio/map',
  '/en/france/paris-nanterre/map',
  '/en/france/ouistreham/map',
  '/en/france/carnac/map',
  '/en/france/bourg-les-valence/map',
  '/en/france/le-coteau-2/map',
  '/en/france/golfe-juan/map',
  '/en/france/lisses/map',
  '/en/france/batz-sur-mer/map',
  '/en/france/merignac/map',
  '/en/france/vineuil-saint-firmin-chantilly/map',
  '/en/france/honfleur/map',
  '/en/france/collegien-2/map',
  '/en/france/lisle-adam/map',
  '/en/france/olivet/map',
  '/en/france/saint-nazaire/map',
  '/en/france/saint-trojan-les-bains-2/map',
  '/en/france/villepinte/map',
  '/en/france/chambray-les-tours-2/map',
  '/en/france/lisle-adam-2/map',
  '/en/france/mouilleron-le-captif-2/map',
  '/en/france/harfleur/map',
  '/en/france/pornichet/map',
  '/en/france/tarbes/map',
  '/en/france/saint-remy-de-provence/map',
  '/en/france/marcq-en-baroeul-3/map',
  '/en/france/saint-maixent-lecole/map',
  '/en/france/bourg-de-peage/map',
  '/en/france/douai/map',
  '/en/france/laxou-3/map',
  '/en/france/montaigu/map',
  '/en/france/le-pouliguen/map',
  '/en/france/le-croisic/map',
  '/en/france/tourcoing/map',
  '/en/france/challans/map',
  '/en/france/rumilly/map',
  '/fr/france/villepinte/map',
  '/fr/france/le-croisic/map',
  '/fr/france/lisses/map',
  '/fr/france/pornichet/map',
  '/fr/france/roanne/map',
  '/fr/france/paris-nanterre/map',
  '/fr/france/carnac/map',
  '/fr/france/golfe-juan/map',
  '/fr/france/meylan-2/map',
  '/fr/france/bourg-de-peage/map',
  '/fr/france/saint-trojan-les-bains-2/map',
  '/fr/france/chambray-les-tours-2/map',
  '/fr/france/harfleur/map',
  '/fr/france/montaigu/map',
  '/fr/france/lisle-adam/map',
  '/fr/france/paris-rueil-malmaison/map',
  '/fr/france/mouilleron-le-captif-2/map',
  '/fr/france/montpellier-mauguio/map',
  '/fr/france/batz-sur-mer/map',
  '/fr/france/saint-remy-de-provence/map',
  '/fr/france/saint-herblain-2/map',
  '/fr/france/olivet/map',
  '/fr/france/nantes-2/map',
  '/fr/france/rumilly/map',
  '/fr/france/le-coteau-2/map',
  '/fr/france/le-pouliguen/map',
  '/fr/france/marcq-en-baroeul-3/map',
  '/fr/france/saint-nazaire/map',
  '/fr/france/douai/map',
  '/fr/france/laxou-3/map',
  '/fr/france/saint-maixent-lecole/map',
  '/fr/france/bourg-les-valence/map',
  '/fr/france/merignac/map',
  '/fr/france/collegien-2/map',
  '/fr/france/ajaccio/map',
  '/fr/france/tarbes/map',
  '/fr/france/honfleur/map',
  '/fr/france/challans/map',
  '/fr/france/tourcoing/map',
  '/fr/france/ouistreham/map',
  '/fr/emirats-arabes-unis/ras-al-khaimah/map'
];

export const EDITORIAL_TO_REDIRECT: Array<string> = [
  '/en/collection/our-top-10-luxury-restaurants-in-france',
  '/en/collection/top-10-family-friendly-restaurants',
  '/fr/collection/top-10-des-restaurants-haut-de-gamme-en-france',
  '/en/collection/top-10-restaurants-for-mediterranean-cuisine',
  '/en/collection/top-10-restaurants-with-a-garden-2',
  '/en/collection/top-10-bistronomic-restaurants',
  '/fr/collection/les-10-meilleurs-restaurants-ou-manger-en-famille',
  '/en/collection/top-10-restaurants-for-a-scrumptious-french-brunch',
  '/fr/collection/les-10-meilleurs-restaurants-de-cuisine-mediterraneenne',
  '/fr/collection/les-10-meilleurs-restaurants-avec-jardin-2',
  '/fr/collection/les-10-meilleurs-restaurants-bistronomiques',
  '/en/collection/celebrate-easter-in-our-restaurants',
  '/en/collection/the-top-restaurants-for-end-of-year-business-and-social-events-2024',
  '/en/collection/top-10-restaurants-for-christmas-offers',
  '/en/collection/top-10-restaurants-for-cosy-winter-dining',
  '/en/collection/top-10-restaurants-for-a-memorable-team-dinner',
  '/en/collection/top-10-restaurants-for-new-year-offers',
  '/en/collection/top-10-restaurants-for-vegetarian-dining',
  '/en/collection/flavours-from-around-the-world',
  '/fr/collection/les-8-meilleurs-restaurants-pour-un-brunch-typiquement-francais',
  '/fr/collection/les-10-meilleurs-restaurants-pour-un-brunch-typiquement-francais',
  '/fr/collection/top-10-des-restaurants-cosy-pour-cet-hiver',
  '/fr/collection/votre-prochain-restaurant-dans-un-hotel-pullman',
  '/fr/collection/nos-meilleurs-restaurants-pour-vos-evenements-sociaux-et-business-de-fin-d-annee-2024',
  '/fr/collection/top-10-des-restaurants-pour-celebrer-noel',
  '/fr/collection/les-10-meilleurs-restaurants-pour-un-diner-d-equipe-memorable',
  '/fr/collection/top-10-des-restaurants-pour-feter-le-nouvel-an',
  '/en/collection/top-10-restaurants-for-a-birthday-party',
  '/fr/collection/les-10-restaurants-preferes-des-amateurs-de-poisson',
  '/fr/collection/top-10-restaurants-vegetariens',
  '/fr/collection/celebrez-paques-dans-nos-restaurants',
  '/fr/collection/nos-12-meilleurs-restaurants-de-luxe-pour-savourer-l-ete-indien',
  '/fr/collection/top-10-des-bars-et-restaurants-pour-un-afterwork-convivial',
  '/en/collection/the-top-10-restaurants-for-a-business-lunch',
  '/en/collection/top-10-restaurants-for-a-romantic-dinner',
  '/en/collection/top-10-restaurants-for-celebrating-la-rentree',
  '/fr/collection/les-10-meilleurs-restaurants-pour-feter-un-anniversaire',
  '/fr/collection/les-10-meilleurs-restaurants-pour-un-dejeuner-d-affaires',
  '/fr/collection/top-10-des-meilleurs-restaurants-pour-un-diner-romantique',
  '/fr/collection/top-10-des-meilleurs-restaurants-pour-celebrer-la-rentree',
  '/en/offer/savor-love-our-restaurants-to-celebrate-valentine-s-day-2025',
  '/fr/offer/savourez-l-amour-nos-restaurants-pour-feter-la-saint-valentin-en-2025',
  '/en/offer/celebrate-christmas-and-new-year-s-2024-festive-offers-in-our-restaurants',
  '/en/offer/italy-at-your-table',
  '/fr/offer/celebrez-noel-et-le-nouvel-an-2024-offres-festives-dans-nos-restaurants',
  '/fr/offer/vivez-halloween-chez-all-restaurants-and-bars',
  '/fr/offer/l-italie-a-votre-table',
  '/en/offer/tribes-offer-modern-and-user-friendly-our-bold-promise',
  '/en/offer/your-restaurant-meals-can-go-a-long-way',
  '/fr/offer/l-offre-chez-tribe-moderne-et-conviviale-voici-notre-bold-promesse',
  '/fr/offer/armony-concept-inedit-autour-de-la-vitalite-et-de-l-equilibre',
  '/fr/offer/vos-repas-au-restaurant-peuvent-vous-emmener-loin'
];
export const EDITORIAL_CONTENT_TYPE = {
  COLLECTION: 'collection',
  OFFER: 'offer'
};
