export const BOOKING_FUNNEL_MAX = 7;
export const BOOKING_FUNNEL_MIN = 1;
export const BOOKING_FUNNEL_DEFAULT = 2;

export enum PARTNER_ID {
  OPENTABLE = 'Open Table',
  THEFORK = 'The Fork',
  ZENCHEF = 'ZenChef'
}

export enum WIDGET_DOMAIN {
  OPENTABLE = 'opentable',
  THEFORK = 'thefork.com',
  ZENCHEF = 'zenchef'
}

export const APCountriesList = [
  'Thailand',
  'Indonesia',
  'Japan',
  'South Korea',
  'Malaysia',
  'Singapore',
  'Myanmar',
  'Vietnam',
  'Cambodia',
  'Philippines',
  'Laos',
  'Maldives',
  'India',
  'Australia',
  'Fiji',
  'New Zealand',
  'French Polynesia',
  'TH',
  'ID',
  'JP',
  'KR',
  'MY',
  'SG',
  'MM',
  'VN',
  'KH',
  'PH',
  'LA',
  'MV',
  'IN',
  'AU',
  'FJ',
  'NZ',
  'PF'
];

export const MAX_USER_GEO_RANGE_KM = 50;

export const DEFAULT_COUNTRY = 'france';
