export enum Environment {
  Dev = 'dev',
  Oat = 'oat',
  Pro = 'pro',
  Rec = 'rec'
}

export enum storesId {
  City = 'city',
  Poi = 'poi'
}

export enum RestaurantsView {
  List = 'list',
  Map = 'map'
}

export enum Routes {
  City = 'city',
  Country = 'country',
  Map = 'map',
  PointOfInterest = 'point-of-interest'
}

export enum RatingVariant {
  Block = 'block',
  Filter = 'filter',
  Full = 'full',
  OneStar = 'one-star',
  OnlyStars = 'only-stars'
}

export enum LoyaltyVariant {
  Home = 'home',
  Rdp = 'rdp'
}

export enum LoyaltyProgram {
  AccorPlus = 'Accor Plus',
  All = 'All'
}

export enum LoyaltyType {
  Home = 'Country home/Editorial',
  Rdp = 'Restaurant detail'
}

export enum LoyaltyUserStatus {
  LOGGED_IN = 'User logged in',
  LOGGED_OUT = 'User logged out'
}

export type Maybe<T> = T | null;
export type Scalars = {
  Boolean: boolean;
  Date: string;
  DateTime: string;
  Float: number;
  ID: string;
  Int: number;
  JSONObject: any;
  String: string;
  Time: string;
  URL: any;
};

export type Datum = {
  __typename?: 'Datum';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ContactMediumTracking = {
  __typename?: 'ContactMediumTracking';
  createdByHotel?: Maybe<Scalars['String']>;
  createdByOperator?: Maybe<Scalars['String']>;
  createdBySystem?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
  updatedByHotel?: Maybe<Scalars['String']>;
  updatedByOperator?: Maybe<Scalars['String']>;
  updatedBySystem?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['String']>;
};

export type ContactMediumEmailRequested = {
  __typename?: 'ContactMediumEmailRequested';
  email?: Maybe<Scalars['String']>;
  requesteddate?: Maybe<Scalars['String']>;
  tracking?: Maybe<ContactMediumTracking>;
};

export type ContactMediumEmail = {
  __typename?: 'ContactMediumEmail';
  email?: Maybe<Scalars['String']>;
  emailRequested?: Maybe<ContactMediumEmailRequested>;
  isPrimary?: Maybe<Scalars['Boolean']>;
};

export type ContactMediumPhoneNumber = {
  __typename?: 'ContactMediumPhoneNumber';
  isPrimary?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phonePrefix?: Maybe<Scalars['String']>;
  phoneType?: Maybe<Scalars['String']>;
};

export type ContactMediumPostalAddress = {
  __typename?: 'ContactMediumPostalAddress';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  address4?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  intraCommunityVat?: Maybe<Scalars['String']>;
  isAsBilling?: Maybe<Scalars['Boolean']>;
  isPrimary?: Maybe<Scalars['Boolean']>;
  stateCode?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type ContactMediumUsage = {
  __typename?: 'ContactMediumUsage';
  isOptOut?: Maybe<Scalars['Boolean']>;
  optOutDate?: Maybe<Scalars['String']>;
  usageType?: Maybe<Scalars['String']>;
};

export type ContactMediumUsages = {
  __typename?: 'ContactMediumUsages';
  mediumUsage?: Maybe<Array<ContactMediumUsage>>;
};

export type ContactMedium = {
  __typename?: 'ContactMedium';
  contextType?: Maybe<Scalars['String']>;
  emailContact?: Maybe<ContactMediumEmail>;
  id: Scalars['ID'];
  isNpai?: Maybe<Scalars['Boolean']>;
  isOptOut?: Maybe<Scalars['Boolean']>;
  mediumUsages?: Maybe<ContactMediumUsages>;
  optOutDate?: Maybe<Scalars['String']>;
  postalAddress?: Maybe<ContactMediumPostalAddress>;
  telephoneNumber?: Maybe<ContactMediumPhoneNumber>;
  tracking?: Maybe<ContactMediumTracking>;
};

export type ContactMediums = {
  __typename?: 'ContactMediums';
  contactMedium?: Maybe<Array<ContactMedium>>;
};

export type Analytic = {
  __typename?: 'Analytic';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Array<Maybe<Datum>>>;
  description?: Maybe<Scalars['String']>;
};

export type Analytics = {
  __typename?: 'Analytics';
  analytic?: Maybe<Array<Maybe<Analytic>>>;
};

export type Tracking = {
  __typename?: 'Tracking';
  createdByHotel?: Maybe<Scalars['String']>;
  createdByOperator?: Maybe<Scalars['String']>;
  createdBySystem?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['Date']>;
  updatedByHotel?: Maybe<Scalars['String']>;
  updatedByOperator?: Maybe<Scalars['String']>;
  updatedBySystem?: Maybe<Scalars['String']>;
  updatedDate?: Maybe<Scalars['Date']>;
};

export type Cookie = {
  __typename?: 'Cookie';
  DateAcceptation?: Maybe<Scalars['String']>;
  TypeCookie?: Maybe<Scalars['String']>;
  Version?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  tracking?: Maybe<Tracking>;
};

export type Cookies = {
  __typename?: 'Cookies';
  cookie?: Maybe<Array<Maybe<Cookie>>>;
};

export type Moderated = {
  __typename?: 'Moderated';
  category?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Scalars['String']>;
  word?: Maybe<Scalars['String']>;
};

export type Moderation = {
  __typename?: 'Moderation';
  moderated?: Maybe<Array<Maybe<Moderated>>>;
  originalComment?: Maybe<Scalars['String']>;
};

export type ExperienceTrackingContent = {
  __typename?: 'ExperienceTrackingContent';
  categoryCode?: Maybe<Scalars['String']>;
  checkOutDate?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  compensationCode?: Maybe<Scalars['String']>;
  courtesyPoints?: Maybe<Scalars['Int']>;
  creationDate?: Maybe<Scalars['Date']>;
  eventDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  languageCode?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['String']>;
  moderation?: Maybe<Moderation>;
  reasonCode?: Maybe<Scalars['String']>;
  satisfactionCode?: Maybe<Scalars['String']>;
  updateDate?: Maybe<Scalars['Date']>;
  visibility?: Maybe<Scalars['String']>;
};

export type ExperienceTracking = {
  __typename?: 'ExperienceTracking';
  experienceTrackingContent?: Maybe<Array<Maybe<ExperienceTrackingContent>>>;
  hotelCode?: Maybe<Scalars['String']>;
};

export type ExperiencesTracking = {
  __typename?: 'ExperiencesTracking';
  experienceTracking?: Maybe<Array<Maybe<ExperienceTracking>>>;
};

export type ExternalSystem = {
  __typename?: 'ExternalSystem';
  externalId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  systemCode?: Maybe<Scalars['String']>;
  tracking?: Maybe<Tracking>;
  updateDate?: Maybe<Scalars['Date']>;
};

export type ExternalSystems = {
  __typename?: 'ExternalSystems';
  externalSystem?: Maybe<Array<Maybe<ExternalSystem>>>;
};

export type Child = {
  __typename?: 'Child';
  birthdate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  tracking?: Maybe<Tracking>;
};

export type Children = {
  __typename?: 'Children';
  child?: Maybe<Array<Maybe<Child>>>;
};

export type FavoriteHotel = {
  __typename?: 'FavoriteHotel';
  hotelCode?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tracking?: Maybe<Tracking>;
};

export type FavoriteHotels = {
  __typename?: 'FavoriteHotels';
  favoriteHotel?: Maybe<Array<Maybe<FavoriteHotel>>>;
};

export type Favorites = {
  __typename?: 'Favorites';
  hotels?: Maybe<Array<Maybe<FavoriteHotel>>>;
};

export type IndividualContracts = {
  __typename?: 'IndividualContracts';
  accessCode?: Maybe<Scalars['String']>;
  companySCCode?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  tracking?: Maybe<Tracking>;
};

export type IndividualLegalIdentification = {
  __typename?: 'IndividualLegalIdentification';
  Int?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  expirationDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  issuedDate?: Maybe<Scalars['String']>;
  issuingCountry?: Maybe<Scalars['String']>;
  issuingPlace?: Maybe<Scalars['String']>;
  nationalityCode?: Maybe<Scalars['String']>;
  tracking?: Maybe<Tracking>;
  type?: Maybe<Scalars['String']>;
  validationDate?: Maybe<Scalars['String']>;
};

export type IndividualLegalIdentifications = {
  __typename?: 'IndividualLegalIdentifications';
  individualLegalIdentification?: Maybe<Array<Maybe<IndividualLegalIdentification>>>;
};

export type IndividualName = {
  __typename?: 'IndividualName';
  civility?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  suffix?: Maybe<Scalars['String']>;
};

export type Label = {
  __typename?: 'Label';
  label?: Maybe<Scalars['String']>;
  languageCodeAlpha2?: Maybe<Scalars['String']>;
  languageCodeAlpha3?: Maybe<Scalars['String']>;
  languageCodeISO?: Maybe<Scalars['String']>;
};

export type PreferenceLabels = {
  __typename?: 'PreferenceLabels';
  label?: Maybe<Array<Maybe<Label>>>;
};

export type Preference = {
  __typename?: 'Preference';
  code?: Maybe<Scalars['String']>;
  defaultName?: Maybe<Scalars['String']>;
  preferenceLabels?: Maybe<PreferenceLabels>;
  valuesType?: Maybe<Scalars['String']>;
};

export type PreferenceFamilyLabels = {
  __typename?: 'PreferenceFamilyLabels';
  label?: Maybe<Array<Maybe<Label>>>;
};

export type PreferenceValueLabels = {
  __typename?: 'PreferenceValueLabels';
  label?: Maybe<Array<Maybe<Label>>>;
};

export type Brand = {
  __typename?: 'Brand';
  code?: Maybe<Scalars['String']>;
};

export type BrandSegment = {
  __typename?: 'BrandSegment';
  brands?: Maybe<Array<Maybe<Brand>>>;
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type PreferenceValue = {
  __typename?: 'PreferenceValue';
  brandSegment?: Maybe<BrandSegment>;
  code?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentLanguage?: Maybe<Scalars['String']>;
  commentTitle?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<Scalars['String']>;
  createdByHotel?: Maybe<Scalars['String']>;
  defaultName?: Maybe<Scalars['String']>;
  favoriteCentral?: Maybe<Scalars['Boolean']>;
  favoriteClient?: Maybe<Scalars['Boolean']>;
  favoriteHotels?: Maybe<FavoriteHotels>;
  id?: Maybe<Scalars['String']>;
  moderation?: Maybe<Moderation>;
  nature?: Maybe<Scalars['String']>;
  optinDate?: Maybe<Scalars['String']>;
  preferenceValueLabels?: Maybe<PreferenceValueLabels>;
  sensitive?: Maybe<Scalars['Boolean']>;
  tracking?: Maybe<Tracking>;
  updated?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type PreferenceValues = {
  __typename?: 'PreferenceValues';
  preferenceValue?: Maybe<Array<Maybe<PreferenceValue>>>;
};

export type IndividualPreference = {
  __typename?: 'IndividualPreference';
  preference?: Maybe<Preference>;
  preferenceValues?: Maybe<PreferenceValues>;
};

export type IndividualPreferences = {
  __typename?: 'IndividualPreferences';
  individualPreference?: Maybe<Array<Maybe<IndividualPreference>>>;
};

export type IndividualPreferenceFamily = {
  __typename?: 'IndividualPreferenceFamily';
  code?: Maybe<Scalars['String']>;
  individualPreferences?: Maybe<IndividualPreferences>;
  preferenceFamilyLabels?: Maybe<PreferenceFamilyLabels>;
};

export type IndividualPreferenceFamilies = {
  __typename?: 'IndividualPreferenceFamilies';
  individualPreferenceFamily?: Maybe<Array<Maybe<IndividualPreferenceFamily>>>;
};

export type Communication = {
  __typename?: 'Communication';
  channel?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type SubscriptionLabels = {
  __typename?: 'SubscriptionLabels';
  label?: Maybe<Array<Maybe<Label>>>;
};

export type UnsubscriptionReasonLabels = {
  __typename?: 'UnsubscriptionReasonLabels';
  label?: Maybe<Array<Maybe<Label>>>;
};

export type IndividualSubscription = {
  __typename?: 'IndividualSubscription';
  code?: Maybe<Scalars['String']>;
  communication?: Maybe<Communication>;
  id?: Maybe<Scalars['String']>;
  optin?: Maybe<Scalars['Boolean']>;
  origine?: Maybe<Scalars['String']>;
  subscribeDate?: Maybe<Scalars['String']>;
  subscriptionLabels?: Maybe<SubscriptionLabels>;
  tracking?: Maybe<Tracking>;
  unsubscribeDate?: Maybe<Scalars['String']>;
  unsubscribeReason?: Maybe<Scalars['String']>;
  unsubscriptionReasonLabels?: Maybe<UnsubscriptionReasonLabels>;
};

export type IndividualSubscriptions = {
  __typename?: 'IndividualSubscriptions';
  individualSubscription?: Maybe<Array<Maybe<IndividualSubscription>>>;
};

export type OptOut = {
  __typename?: 'OptOut';
  isOptOutTotal?: Maybe<Scalars['Boolean']>;
  optOutDate?: Maybe<Scalars['String']>;
};

export type VipReasonLabels = {
  __typename?: 'VipReasonLabels';
  label?: Maybe<Array<Maybe<Label>>>;
};

export type VipReasonHotelLike = {
  __typename?: 'VipReasonHotelLike';
  hotelCode?: Maybe<Scalars['String']>;
  tracking?: Maybe<Tracking>;
};

export type VipReasonHotelLikes = {
  __typename?: 'VipReasonHotelLikes';
  vipReasonHotelLike?: Maybe<Array<Maybe<VipReasonHotelLike>>>;
};

export type VipReason = {
  __typename?: 'VipReason';
  code?: Maybe<Scalars['String']>;
  createdByHotelCode?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['String']>;
  tracking?: Maybe<Tracking>;
  updatedate?: Maybe<Scalars['Date']>;
  vipReasonHotelLikes?: Maybe<VipReasonHotelLikes>;
  vipReasonLabels?: Maybe<VipReasonLabels>;
};

export type VipReasons = {
  __typename?: 'VipReasons';
  vipReason?: Maybe<Array<Maybe<VipReason>>>;
};

export type Individual = {
  __typename?: 'Individual';
  birthDate?: Maybe<Scalars['String']>;
  children?: Maybe<Children>;
  favorites?: Maybe<Favorites>;
  gender?: Maybe<Scalars['String']>;
  hasBooking?: Maybe<Scalars['Boolean']>;
  hasHotelFavorite?: Maybe<Scalars['Boolean']>;
  hasLoyaltyCard?: Maybe<Scalars['Boolean']>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  hasPreference?: Maybe<Scalars['Boolean']>;
  hasSegment?: Maybe<Scalars['Boolean']>;
  hasSubscriptionCard?: Maybe<Scalars['Boolean']>;
  hasWallet?: Maybe<Scalars['Boolean']>;
  individualContracts?: Maybe<IndividualContracts>;
  individualLegalIdentifications?: Maybe<IndividualLegalIdentifications>;
  individualName?: Maybe<IndividualName>;
  individualPreferenceFamilies?: Maybe<IndividualPreferenceFamilies>;
  individualSubscriptions?: Maybe<IndividualSubscriptions>;
  isHighValueContact?: Maybe<Scalars['Boolean']>;
  isLoyaltyMember?: Maybe<Scalars['Boolean']>;
  isMerged?: Maybe<Scalars['Boolean']>;
  isReachable?: Maybe<Scalars['Boolean']>;
  isRelevant?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  mustUpdatePassword?: Maybe<Scalars['Boolean']>;
  nationality?: Maybe<Scalars['String']>;
  noShareToHotel?: Maybe<Scalars['String']>;
  optOut?: Maybe<OptOut>;
  placeOfBirth?: Maybe<Scalars['String']>;
  pmid?: Maybe<Scalars['String']>;
  registrationChannel?: Maybe<Scalars['String']>;
  tracking?: Maybe<Tracking>;
  uaUserId?: Maybe<Scalars['String']>;
  vipCode?: Maybe<Scalars['String']>;
  vipReasons?: Maybe<VipReasons>;
};

export type LeadLabels = {
  __typename?: 'LeadLabels';
  label?: Maybe<Array<Maybe<Label>>>;
};

export type Lead = {
  __typename?: 'Lead';
  code?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  leadLabels?: Maybe<LeadLabels>;
  tracking?: Maybe<Tracking>;
};

export type Leads = {
  __typename?: 'Leads';
  lead?: Maybe<Array<Maybe<Lead>>>;
};

export type Links = {
  __typename?: 'Links';
  link?: Maybe<Array<Maybe<Array<Maybe<Scalars['JSONObject']>>>>>;
};

export type RepaymentDetail = {
  __typename?: 'RepaymentDetail';
  amountInCurrency?: Maybe<Scalars['Int']>;
  amountInEuro?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  nbDecimalsCurrency?: Maybe<Scalars['Int']>;
  refundDate?: Maybe<Scalars['Date']>;
  refundRate?: Maybe<Scalars['Int']>;
};

export type StayDetail = {
  __typename?: 'StayDetail';
  bookingValueDate?: Maybe<Scalars['String']>;
  checkInDate?: Maybe<Scalars['String']>;
  checkOutDate?: Maybe<Scalars['String']>;
  hotelCode?: Maybe<Scalars['String']>;
  hotelName?: Maybe<Scalars['String']>;
  redeemedLoginHL?: Maybe<Scalars['String']>;
  redemptionDate?: Maybe<Scalars['String']>;
  roomInt?: Maybe<Scalars['String']>;
  tarsBookingInt?: Maybe<Scalars['String']>;
  tarsFileInt?: Maybe<Scalars['String']>;
};

export type Award = {
  __typename?: 'Award';
  awardId?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['Date']>;
  cancellationReason?: Maybe<Scalars['String']>;
  creationCause?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['Date']>;
  expirationDate?: Maybe<Scalars['String']>;
  repaymentDetail?: Maybe<RepaymentDetail>;
  status?: Maybe<Scalars['String']>;
  stayDetail?: Maybe<StayDetail>;
  triggedDate?: Maybe<Scalars['String']>;
  updateDate?: Maybe<Scalars['Date']>;
};

export type Awards = {
  __typename?: 'Awards';
  award?: Maybe<Array<Maybe<Award>>>;
};

export type AwardType = {
  __typename?: 'AwardType';
  IntOfAwards?: Maybe<Scalars['Int']>;
  IntOfRemainingAwards?: Maybe<Scalars['Int']>;
  awardTypeName?: Maybe<Scalars['String']>;
  awards?: Maybe<Awards>;
  nightsForNextAward?: Maybe<Scalars['Int']>;
  statusPointsForNextAward?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

export type AwardTypes = {
  __typename?: 'AwardTypes';
  allTypesAwardEligibility?: Maybe<Scalars['String']>;
  awardType?: Maybe<Array<Maybe<AwardType>>>;
};

export type Balances = {
  __typename?: 'Balances';
  currentNightsBalance?: Maybe<Scalars['Int']>;
  currentStaysBalance?: Maybe<Scalars['Int']>;
  fastTrackedStatusNights?: Maybe<Scalars['Int']>;
  nbNightsToNextTiering?: Maybe<Scalars['Int']>;
  nbPoints?: Maybe<Scalars['Int']>;
  nbPointsEarnLast12Months?: Maybe<Scalars['Int']>;
  nbPointsToNextTiering?: Maybe<Scalars['Int']>;
  nightsSpentOnTierUpdate?: Maybe<Scalars['Int']>;
  pointsEarnedOnTierUpdate?: Maybe<Scalars['Int']>;
};

export type CoBrandedCard = {
  __typename?: 'CoBrandedCard';
  cardInt?: Maybe<Scalars['String']>;
  cardType?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  partnerCode?: Maybe<Scalars['String']>;
  partnerName?: Maybe<Scalars['String']>;
  programmeName?: Maybe<Scalars['String']>;
  subscriptionDate?: Maybe<Scalars['String']>;
};

export type CoBrandedCards = {
  __typename?: 'CoBrandedCards';
  coBrandedCard?: Maybe<Scalars['JSONObject']>;
};

export type Link = {
  __typename?: 'Link';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CardLinks = {
  __typename?: 'CardLinks';
  link?: Maybe<Array<Maybe<Link>>>;
};

export type CardProduct = {
  __typename?: 'CardProduct';
  active?: Maybe<Scalars['Boolean']>;
  cardCodeTARS?: Maybe<Scalars['String']>;
  productBin?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  productLabel?: Maybe<Scalars['String']>;
  productTier?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
};

export type Card3 = {
  __typename?: 'Card3';
  beginDate?: Maybe<Scalars['String']>;
  cardInt?: Maybe<Scalars['String']>;
  cardProduct?: Maybe<CardProduct>;
  isLastActiveCard?: Maybe<Scalars['Boolean']>;
  isPrintable?: Maybe<Scalars['Boolean']>;
  temporary?: Maybe<Scalars['Boolean']>;
  termsAndConditionsAcceptanceDate?: Maybe<Scalars['String']>;
};

export type GivenCardProduct = {
  __typename?: 'GivenCardProduct';
  active?: Maybe<Scalars['Boolean']>;
  cardCodeTARS?: Maybe<Scalars['String']>;
  productBin?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  productLabel?: Maybe<Scalars['String']>;
  productTier?: Maybe<Scalars['String']>;
  productType?: Maybe<Scalars['String']>;
};

export type StatusGift = {
  __typename?: 'StatusGift';
  beneficiaryCardInt?: Maybe<Scalars['String']>;
  beneficiaryPmid?: Maybe<Scalars['String']>;
  beneficiaryStatusExpirationDate?: Maybe<Scalars['String']>;
  giftExpirationDate?: Maybe<Scalars['String']>;
  givenCardProduct?: Maybe<GivenCardProduct>;
  statusGiftDate?: Maybe<Scalars['String']>;
};

export type Card = {
  __typename?: 'Card';
  beginDate?: Maybe<Scalars['String']>;
  cardInt?: Maybe<Scalars['String']>;
  cardLinks?: Maybe<CardLinks>;
  cardNumber?: Maybe<Scalars['String']>;
  cardProduct?: Maybe<CardProduct>;
  companyStampedName?: Maybe<Scalars['String']>;
  duplicationStatus?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  isLastActiveCard?: Maybe<Scalars['Boolean']>;
  isPrintable?: Maybe<Scalars['Boolean']>;
  stampedName?: Maybe<Scalars['String']>;
  statusGift?: Maybe<StatusGift>;
  temporary?: Maybe<Scalars['Boolean']>;
  termsAndConditionsAcceptanceDate?: Maybe<Scalars['String']>;
};

export type CobrandedRateCards = {
  __typename?: 'CobrandedRateCards';
  card?: Maybe<Array<Maybe<Card>>>;
};

export type Freenight = {
  __typename?: 'Freenight';
  checkInDate?: Maybe<Scalars['String']>;
  checkOutDate?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['Date']>;
  expirationDate?: Maybe<Scalars['String']>;
  hotelCode?: Maybe<Scalars['String']>;
  hotelName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  modificationDate?: Maybe<Scalars['Date']>;
  status?: Maybe<Scalars['String']>;
  tarsFileInt?: Maybe<Scalars['String']>;
};

export type Freenights = {
  __typename?: 'Freenights';
  freenight?: Maybe<Array<Maybe<Freenight>>>;
};

export type AccorAdvantagesEligibility = {
  __typename?: 'AccorAdvantagesEligibility';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type LinkPartner = {
  __typename?: 'LinkPartner';
  accorAdvantagesEligibility?: Maybe<Array<Maybe<AccorAdvantagesEligibility>>>;
  isSwitchable?: Maybe<Scalars['Boolean']>;
  linkEligibile?: Maybe<Scalars['Boolean']>;
  linkEligibleDate?: Maybe<Scalars['String']>;
  partnerCode?: Maybe<Scalars['String']>;
  uneligibilityReason?: Maybe<Scalars['String']>;
};

export type LoyaltyCards = {
  __typename?: 'LoyaltyCards';
  card?: Maybe<Array<Maybe<Card>>>;
  statusGiftEligibility?: Maybe<Scalars['String']>;
};

export type MemberInfo = {
  __typename?: 'MemberInfo';
  allTypesAwardEligibility?: Maybe<Scalars['String']>;
  displayBurnOnline?: Maybe<Scalars['Boolean']>;
  enrollCompletionReminderEmailSent?: Maybe<Scalars['Boolean']>;
  enrolledBeforeGDPRCompliancy?: Maybe<Scalars['Boolean']>;
  fullEnrolmentDate?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  loyaltyTermsAndConditionsAcceptancedate?: Maybe<Scalars['String']>;
  meetingPlannerAuthorizationEnddate?: Maybe<Scalars['String']>;
  memberPhysicalCard?: Maybe<Scalars['Boolean']>;
  nextTiering?: Maybe<Scalars['String']>;
  pointsExpirationdate?: Maybe<Scalars['String']>;
  preEnrollExpirationDate?: Maybe<Scalars['String']>;
  qrCode?: Maybe<Scalars['JSONObject']>;
  status?: Maybe<Scalars['String']>;
  userHashKey?: Maybe<Scalars['String']>;
};

export type UserOperation = {
  __typename?: 'UserOperation';
  cancelled?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  fileInt?: Maybe<Scalars['String']>;
  nightsCount?: Maybe<Scalars['Int']>;
  offerCode?: Maybe<Scalars['String']>;
  offerName?: Maybe<Scalars['String']>;
  operationDate?: Maybe<Scalars['Date']>;
  points?: Maybe<Scalars['Int']>;
  statusPoints?: Maybe<Scalars['Int']>;
  transactionDate?: Maybe<Scalars['Date']>;
  type?: Maybe<Scalars['String']>;
};

export type Operations = {
  __typename?: 'Operations';
  maxResultOperationsExceeded?: Maybe<Scalars['Boolean']>;
  operation?: Maybe<Array<Maybe<UserOperation>>>;
};

export type CriteriaBonus = {
  __typename?: 'CriteriaBonus';
  bookingBeginDate?: Maybe<Scalars['String']>;
  bookingEndDate?: Maybe<Scalars['String']>;
  minTurnOverStay?: Maybe<Scalars['String']>;
  stayBeginDate?: Maybe<Scalars['String']>;
  stayEndDate?: Maybe<Scalars['String']>;
  stayMinNights?: Maybe<Scalars['Int']>;
};

export type InformationBonus = {
  __typename?: 'InformationBonus';
  individualBonusType?: Maybe<Scalars['String']>;
  limitedIntOfUse?: Maybe<Scalars['String']>;
  nohetoId?: Maybe<Scalars['String']>;
  tarsKeys?: Maybe<Scalars['String']>;
  wiseId?: Maybe<Scalars['String']>;
};

export type NightsForAProduct = {
  __typename?: 'NightsForAProduct';
  productCode?: Maybe<Scalars['Int']>;
  productLabel?: Maybe<Scalars['String']>;
};

export type NbNightsByProduct = {
  __typename?: 'NbNightsByProduct';
  multiplyingPointsCoeff?: Maybe<Scalars['String']>;
  nbNights?: Maybe<Scalars['Int']>;
  nbPointsStay1?: Maybe<Scalars['Int']>;
  nbPointsStay2?: Maybe<Scalars['Int']>;
  nbPointsStay3?: Maybe<Scalars['Int']>;
  nbPointsStay4?: Maybe<Scalars['Int']>;
  nightsForAProduct?: Maybe<Array<Maybe<NightsForAProduct>>>;
};

export type PromiseBonus = {
  __typename?: 'PromiseBonus';
  nbNightsByProduct?: Maybe<NbNightsByProduct>;
  nbPoints?: Maybe<Scalars['Int']>;
};

export type SubscribedBonus = {
  __typename?: 'SubscribedBonus';
  code?: Maybe<Scalars['String']>;
  criteriaBonus?: Maybe<CriteriaBonus>;
  enrollmentDate?: Maybe<Scalars['String']>;
  informationBonus?: Maybe<InformationBonus>;
  name?: Maybe<Scalars['String']>;
  promiseBonus?: Maybe<PromiseBonus>;
  type?: Maybe<Scalars['String']>;
};

export type SubscribedBonuses = {
  __typename?: 'SubscribedBonuses';
  subscribedBonus?: Maybe<Array<Maybe<SubscribedBonus>>>;
};

export type SubscriptionCards = {
  __typename?: 'SubscriptionCards';
  card?: Maybe<Array<Maybe<Card>>>;
  statusGiftEligibility?: Maybe<Scalars['String']>;
};

export type Loyalty = {
  __typename?: 'Loyalty';
  awardTypes?: Maybe<AwardTypes>;
  balances?: Maybe<Balances>;
  coBrandedCards?: Maybe<CoBrandedCards>;
  cobrandedRateCards?: Maybe<CobrandedRateCards>;
  freenights?: Maybe<Freenights>;
  linkPartners?: Maybe<Array<Maybe<LinkPartner>>>;
  links?: Maybe<Links>;
  loyaltyCards?: Maybe<LoyaltyCards>;
  memberInfo?: Maybe<MemberInfo>;
  operations?: Maybe<Operations>;
  subscribedBonuses?: Maybe<SubscribedBonuses>;
  subscriptionCards?: Maybe<SubscriptionCards>;
};

export type Aggregate = {
  __typename?: 'Aggregate';
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type QuestionAnswer = {
  __typename?: 'QuestionAnswer';
  answer?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type Survey = {
  __typename?: 'Survey';
  aggregates?: Maybe<Array<Maybe<Aggregate>>>;
  file?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  questionAnswers?: Maybe<Array<Maybe<QuestionAnswer>>>;
  stayDetail?: Maybe<StayDetail>;
};

export type Surveys = {
  __typename?: 'Surveys';
  aggregates?: Maybe<Array<Maybe<Aggregate>>>;
  survey?: Maybe<Array<Maybe<Survey>>>;
};

export type Wallet = {
  __typename?: 'Wallet';
  creationDate?: Maybe<Scalars['Date']>;
  endUse?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startUse?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updateDate?: Maybe<Scalars['Date']>;
};

export type Wallets = {
  __typename?: 'Wallets';
  wallet?: Maybe<Array<Maybe<Wallet>>>;
};

export type CustomerInformation = {
  __typename?: 'CustomerInformation';
  analytics?: Maybe<Analytics>;
  contactMediums?: Maybe<ContactMediums>;
  cookies?: Maybe<Cookies>;
  creationDate?: Maybe<Scalars['Date']>;
  creationSourceSystem?: Maybe<Scalars['String']>;
  experiencesTracking?: Maybe<ExperiencesTracking>;
  externalSystems?: Maybe<ExternalSystems>;
  id?: Maybe<Scalars['String']>;
  individual?: Maybe<Individual>;
  internalId?: Maybe<Scalars['String']>;
  leads?: Maybe<Leads>;
  loyalty?: Maybe<Loyalty>;
  partyType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  surveys?: Maybe<Surveys>;
  userCity?: Maybe<Scalars['String']>;
  userCountry?: Maybe<Scalars['String']>;
  userLat?: Maybe<Scalars['Float']>;
  userLong?: Maybe<Scalars['Float']>;
  wallets?: Maybe<Wallets>;
};

export type UserContext = {
  __typename?: 'UserContext';
  userCity?: Maybe<Scalars['String']>;
  userCountry?: Maybe<Scalars['String']>;
  userLat?: Maybe<Scalars['Float']>;
  userLong?: Maybe<Scalars['Float']>;
};

export type CustomerEmailContactFragment = { __typename?: 'ContactMediumEmail'; email?: null | string; isPrimary?: boolean | null };

export type CustomerPhoneNumberFragment = {
  __typename?: 'ContactMediumPhoneNumber';
  isPrimary?: boolean | null;
  phoneNumber?: null | string;
  phonePrefix?: null | string;
};

export type CustomerPostalAddressFragment = {
  __typename?: 'ContactMediumPostalAddress';
  address1?: null | string;
  address2?: null | string;
  address3?: null | string;
  address4?: null | string;
  city?: null | string;
  countryCode?: null | string;
  isPrimary?: boolean | null;
  stateCode?: null | string;
  zipCode?: null | string;
};

export type UserInformation = CustomerInformation | UserContext;
