// reused from all-inclusive_apps/packages/all-inclusive-app/domains/user/store.ts

import { defineStore } from 'pinia';

import {
  Card,
  CustomerEmailContactFragment,
  CustomerInformation,
  CustomerPhoneNumberFragment,
  CustomerPostalAddressFragment,
  IndividualName,
  Maybe,
  UserContext,
  UserInformation
} from '~/core';
import { APCountriesList } from '~/helpers/constants';

type UserBookingForm = {
  email?: string;
  firstName?: string;
  hotelRoom?: string;
  lastName?: string;
  phoneNumber?: string;
};

export type State = {
  hasIdentificationCookies: boolean;
  hasLoadedUser: boolean;
  isLogged: boolean;
  user: CustomerInformation;
  userBookingForm: UserBookingForm;
  userContext: UserContext;
  userLoadDone: boolean;
};

export type Getters = {
  APZone(state: State): Maybe<boolean>;
  activeLoyaltyCard(state: State): Maybe<Card> | undefined;
  billingAddress(state: State): Maybe<CustomerPostalAddressFragment> | undefined;
  bookingForm(state: State): UserBookingForm | undefined;
  city(state: State): Maybe<String> | undefined;
  civilInformation(state: State): Maybe<IndividualName> | undefined;
  countryCode(state: State): Maybe<String> | undefined;
  emailContact(state: State): Maybe<CustomerEmailContactFragment> | undefined;
  firstName(state: State): Maybe<String> | undefined;
  individualSubscription(state: State): Maybe<boolean>;
  isAPUser(state: State): Maybe<boolean>;
  isLoyalUser(state: State): boolean;
  lastName(state: State): Maybe<String> | undefined;
  loyaltyCardCode(): string;
  loyaltyCardLabel(): string;
  loyaltyCardNumber(): string;
  loyaltyCardTypes(state: State): string;
  loyaltyNbPoints(state: State): Maybe<Number> | undefined;
  loyaltyStatus(): string;
  phoneNumber(state: State): Maybe<CustomerPhoneNumberFragment> | undefined;
  primaryAddress(state: State): Maybe<CustomerPostalAddressFragment> | undefined;
  userId(state: State): Maybe<String> | undefined;
};

export type Actions = {
  loadUser(): Promise<void>;
};

export const useUserStore = defineStore<'user', State, Getters, Actions>('user', {
  actions: {
    async loadUser() {
      const user: UserInformation = await $fetch<UserInformation>('/customer/info');
      const { setCountryName, setGeolocation } = useGeolocationStore();
      if (user) {
        switch (user.__typename) {
          case 'CustomerInformation':
            this.user = user ?? {};
            this.userContext = {
              userCity: user.userCity,
              userCountry: user.userCountry
            };
            if (this.user.individual) {
              this.isLogged = true;
              this.hasLoadedUser = true;
            }
            break;
          case 'UserContext':
            this.userContext = user ?? {};
            break;
        }
        setCountryName(user.userCountry as string);
        setGeolocation(user.userLat as number, user.userLong as number);
      }

      this.userLoadDone = true;
    }
  },
  getters: {
    APZone(state: State): Maybe<boolean> {
      return APCountriesList.includes(state.userContext.userCountry ?? '');
    },
    activeLoyaltyCard(state) {
      return state.user.loyalty?.loyaltyCards?.card?.find((card) => card?.isLastActiveCard);
    },
    billingAddress(state: State): Maybe<CustomerPostalAddressFragment> | undefined {
      return state.user.contactMediums?.contactMedium?.find((contactMedium) =>
        contactMedium.mediumUsages?.mediumUsage?.some((mediumUsage) => mediumUsage.usageType === 'Billing')
      )?.postalAddress;
    },
    bookingForm: (state: State) => {
      return state.userBookingForm;
    },
    city(state: State): Maybe<string> | undefined {
      return state.userContext?.userCity;
    },
    civilInformation(state) {
      return state.user.individual?.individualName;
    },
    country(state: State): Maybe<string> | undefined {
      return state.userContext?.userCountry;
    },
    countryCode(state: State): Maybe<string> | undefined {
      return (
        state.user.contactMediums?.contactMedium?.find((contact) => contact?.postalAddress?.countryCode !== null)?.postalAddress?.countryCode ?? ''
      );
    },
    emailContact(state: State): Maybe<CustomerEmailContactFragment> | undefined {
      return state.user.contactMediums?.contactMedium?.find((contactMedium) => contactMedium.emailContact?.isPrimary)?.emailContact;
    },
    firstName(state) {
      return state.user?.individual?.individualName?.firstName ?? '';
    },
    gtmId(state: State): Maybe<string> | undefined {
      return state.user?.id;
    },
    individualSubscription(state: State): Maybe<boolean> {
      return (
        state.user.individual?.individualSubscriptions?.individualSubscription?.find((individualSub) => individualSub?.optin === true)?.optin ?? false
      );
    },
    isAPUser(state: State): Maybe<boolean> {
      return state.user.loyalty?.loyaltyCards?.card?.some((el) => el?.cardProduct?.productType?.includes('Accor Plus')) ?? false;
    },
    isLoyalUser(state) {
      return state.user?.individual?.isLoyaltyMember || false;
    },
    lastName(state) {
      return state.user?.individual?.individualName?.lastName ?? '';
    },
    loyaltyCardCode() {
      return this.activeLoyaltyCard?.cardProduct?.cardCodeTARS ?? '';
    },
    loyaltyCardLabel() {
      return this.activeLoyaltyCard?.cardProduct?.productLabel ?? '';
    },
    loyaltyCardNumber() {
      return this.activeLoyaltyCard?.cardNumber ?? '';
    },
    loyaltyCardTypes(state: State): string {
      const loyaltyCards = state.user?.loyalty?.loyaltyCards?.card?.find((singleCard) => singleCard?.cardProduct?.cardCodeTARS !== null)?.cardProduct
        ?.cardCodeTARS;
      const subscriptionCards = state.user?.loyalty?.subscriptionCards?.card?.find((singleCard) => singleCard?.cardProduct?.cardCodeTARS !== null)
        ?.cardProduct?.cardCodeTARS;
      const cobrandedRateCards = state.user?.loyalty?.subscriptionCards?.card?.find((singleCard) => singleCard?.cardProduct?.cardCodeTARS !== null)
        ?.cardProduct?.cardCodeTARS;

      return [loyaltyCards, subscriptionCards, cobrandedRateCards].filter(Boolean).join(', ');
    },
    loyaltyNbPoints(state) {
      return state.user?.loyalty?.balances?.nbPoints ?? 0;
    },
    loyaltyStatus() {
      return this.activeLoyaltyCard?.cardProduct?.productTier ?? '';
    },
    phoneNumber(state: State): Maybe<CustomerPhoneNumberFragment> | undefined {
      return state.user.contactMediums?.contactMedium?.find((contactMedium) =>
        contactMedium.mediumUsages?.mediumUsage?.some((mediumUsage) => mediumUsage.usageType === 'Cell')
      )?.telephoneNumber;
    },
    primaryAddress(state: State): Maybe<CustomerPostalAddressFragment> | undefined {
      return state.user.contactMediums?.contactMedium?.find((contactMedium) => contactMedium.postalAddress?.isPrimary)?.postalAddress;
    },
    userId(state: State): Maybe<string> | undefined {
      return state.user?.id;
    }
  },
  state: () => ({
    hasIdentificationCookies: false,
    hasLoadedUser: false,
    isLogged: false,
    user: {},
    userBookingForm: {},
    userContext: {},
    userLoadDone: false
  })
});
