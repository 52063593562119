import revive_payload_client_YTGlmQWWT5 from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/usr/src/app/packages/front/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_VTarsGAzgb from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_xKuw1km5sV from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/app/packages/front/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_01O9J0dmU6 from "/usr/src/app/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import i18n_dOTnCc6TUQ from "/usr/src/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_SeG0EjL5Ec from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import swipe_modal_uKSAmTj6Lt from "/usr/src/app/packages/front/plugins/swipe-modal.ts";
import apollo_client_VTaW5dp4nd from "/usr/src/app/packages/front/plugins/apollo-client.ts";
import vue_plugins_rFJwPzZjsf from "/usr/src/app/packages/front/plugins/vue-plugins.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_VTarsGAzgb,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_01O9J0dmU6,
  i18n_dOTnCc6TUQ,
  chunk_reload_client_SeG0EjL5Ec,
  swipe_modal_uKSAmTj6Lt,
  apollo_client_VTaW5dp4nd,
  vue_plugins_rFJwPzZjsf
]