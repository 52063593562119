import { Environment } from '~/core';
import { useCountryBaseInformationAsyncData, useCountryBaseInformationLazyQuery } from '~/graphql';
import { urlQueryParams } from '~/helpers/urlQueryParams';

const baseCountry = ref<string>();

export const useCountry = () => {
  const { userCountryName } = useGeolocationStore();

  const defaultCountry = useNuxtApp().$i18n.t('pages.homePage.defaultCountry');

  // TODO : there is still an issue here, due to hydration mismatch caused by the fact that the logo is in the header
  // and the header is not hydrated on first load, so the baseCountry is not set yey
  const redirectFromLogo = async () => {
    const localePath = useLocalePath();
    const { query } = useRoute();
    const queryParams = Object.keys(query).length ? `?${urlQueryParams(query)}` : '';
    let redirectionPath = '';

    if (!baseCountry.value) {
      return localePath('/');
    }

    try {
      const { data } = await useCountryBaseInformationAsyncData({
        fallBack: true,
        slug: baseCountry.value.toLocaleLowerCase()
      });
      if (data.value?.countryBaseInformation) {
        redirectionPath = `/${data.value?.countryBaseInformation.slug}${queryParams}`;
      }

      return localePath(redirectionPath);
    } catch (error) {
      return localePath('/');
    }
  };

  const redirectToHomepage = async (): Promise<string> => {
    const headers = useRequestHeaders();
    const config = useRuntimeConfig();
    const { query } = useRoute();

    let redirectionPath = '';
    const localePath = useLocalePath();
    const queryParams = Object.keys(query).length ? `?${urlQueryParams(query)}` : '';
    const country = headers['cloudfront-viewer-country-name'] ?? defaultCountry;
    let slug = country.toLocaleLowerCase() ?? userCountryName?.toLocaleLowerCase() ?? defaultCountry.toLocaleLowerCase();

    if (config.public.appEnv !== Environment.Pro) {
      const forcedCountry = headers['x-cloudfront-viewer-country-name'];
      if (forcedCountry) {
        slug = forcedCountry.toLocaleLowerCase();
      }
    }

    const { load, onResult } = useCountryBaseInformationLazyQuery({
      fallBack: true,
      slug
    });

    onResult((response) => {
      if (response.loading) return;

      if (response.data.countryBaseInformation) {
        redirectionPath = `/${response.data.countryBaseInformation?.slug}${queryParams}`;
      } else {
        redirectionPath = `/${defaultCountry}${queryParams}`;
      }
    });

    await load();

    return localePath(redirectionPath);
  };

  return {
    baseCountry,
    redirectFromLogo,
    redirectToHomepage
  };
};
