<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<script lang="ts" setup>
import { ReactiveHead } from '@unhead/vue';

const { locale } = useI18n();
const userStore = useUserStore();
const runtimeConfig = useRuntimeConfig();

const head = computed<ReactiveHead>(() => {
  return {
    htmlAttrs: {
      lang: locale.value
    },
    link: [
      { href: '/apple-touch-icon.png', rel: 'apple-touch-icon', sizes: '180x180' },
      { href: '/favicon-32x32.png', rel: 'icon', type: 'image/png' },
      { href: '/favicon-16x16.png', rel: 'icon', type: 'image/png' },
      { href: '/favicon.ico', rel: 'icon', type: 'image/png' }
    ],
    script: [
      {
        async: true,
        // TODO : get the correct GTM key, currently the V1 is used
        children: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TKQN7D');
        `
      },
      {
        'data-document-language': true,
        'data-domain-script': runtimeConfig.public.oneTrustToken as string,
        defer: true,
        src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
      }
    ]
  };
});

useHead(head);

onMounted(() => {
  userStore.loadUser();
});
</script>
