import { DEFAULT_COUNTRY } from '~/helpers/constants';
import { getQueryParamsFromUrl } from '~/helpers/url';
import { urlQueryParams } from '~/helpers/urlQueryParams';

export default defineNuxtRouteMiddleware((to) => {
  const isWebView = to.path.includes('/auth/app/redirect');

  if (isWebView) {
    let queryParams: Record<string, any> = { webView: true };
    const cleaningPath = to.path.replace('/auth/app/redirect', '');
    const queryParamsExist = Object.keys(to.query).length;

    if (queryParamsExist) {
      queryParams = { ...queryParams, ...to.query };

      if (queryParams.urlRedirect) {
        // Get query params from urlRedirect to persist them after redirection
        const urlRedirectQueryParams = getQueryParamsFromUrl(queryParams.urlRedirect);

        queryParams = { ...queryParams, ...urlRedirectQueryParams };

        // Remove urlRedirect from query params in case we want to redirect on it but doesn't want to keep it in query params
        delete queryParams.urlRedirect;
      }
    }

    const mappedQueryParams = urlQueryParams(queryParams);

    if (to.query.urlRedirect && typeof to.query.urlRedirect === 'string') {
      const urlRedirect = to.query.urlRedirect;
      // Get the pathname from urlRedirect to avoid to have a redirect on external domain
      const decodedUrl = decodeURIComponent(urlRedirect);
      const pathName = new URL(decodedUrl).pathname;
      return navigateTo(`${pathName}?${mappedQueryParams}`);
    }

    const urlRedirect = cleaningPath ? `${cleaningPath}?${mappedQueryParams}` : `/fr/${DEFAULT_COUNTRY}?${mappedQueryParams}`;

    // This avoid to have a redirect loop
    return navigateTo(urlRedirect !== '/' ? urlRedirect : '/');
  }
});
